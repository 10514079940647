import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Category from "./Category";
import { useGetAllCategories } from "../../hooks/services";
import { useLocation } from "react-router-dom";
import CardSkeleton from "../CardSkeleton";
import Notification from "../Notification";
import { useDispatch } from "react-redux";
import { setCurrentCategory } from "../../Redux/slice/common";

const Categories = ({ columns = { xs: 12, sm: 6, md: 3 }, height = "100" }) => {
  const { data: categories, error, isLoading } = useGetAllCategories();
  const pageLocation = useLocation();
  if(error){
    Notification(error?.message ?? "Internal Server Error.",true)
  }
  const dispatch=useDispatch();
  if(categories && Array.isArray(categories) && categories.length>0){
    dispatch(setCurrentCategory(categories[0]))
  }

  return (
    <>
      {pageLocation.pathname === "/services/all" ? (
        <Box
          maxWidth="lg"
          sx={{
            display: "flex",
            gap: 2,
            width: "100%",
            overflowX: "auto",
            p: 1,
          }}
        >
          {isLoading
            ? new Array(6).fill(undefined)?.map((value,idx) => (
                <Box sx={{ flex: "0 0 auto", minWidth: "200px" }} key={idx}>
                  <CardSkeleton key={idx} height={120} />
                </Box>
              ))
            : categories?.map((cat) => (
                <Box
                  sx={{ flex: "0 0 auto", minWidth: "200px" }}
                  key={cat?.id}
                >
                  <Category category={cat} height={height}  key={cat?.id} />
                </Box>
              ))}
        </Box>
      ) : (
        <Grid container rowSpacing={1} columnSpacing={2}>
          {(isLoading || error)
            ? new Array(8).fill(undefined)?.map((value,idx) => (
                <Grid item xs={columns.xs} sm={columns.sm} md={columns.md} key={idx}>
                  <CardSkeleton key={idx} />
                </Grid>
              ))
            : categories?.map((cat) => (
                <Grid item xs={columns.xs} sm={columns.sm} md={columns.md} key={cat?.id}>
                  <Category
                    category={cat}
                    key={cat?.id}
                    columns={columns}
                    height={height}
                  />
                </Grid>
              ))}
        </Grid>
      )}
    </>
  );
};

export default Categories;
