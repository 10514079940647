import {
    Container,
    Card,
    CardMedia,
    CardContent,
    Grid,
    Breadcrumbs,
    Typography,
    Rating,
    Button,
    Link, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Stack
} from "@mui/material";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import ProviderService from "../../services/provider-service";
import Footer from "../common/footer";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

function Providers() {
    const params = useParams();
    const [providers, setProviders] = useState([])
    const [bookingDialogVisible, setBookingDialogVisible] = useState(false)

    const fetchProviderList = () => {
        ProviderService.fetchProviderList(params.subCategoryCode)
            .then(response => {
                console.log(response?.data?.data)
                setProviders(response?.data?.data)
            })
            .catch(error => {

            })
    }

    const bookService = () => {

    }

    useEffect(() => {
        console.log(params)
        fetchProviderList()
    }, []);

    return (
        <div>
            <div style={{
                textAlign: 'left',
                fontSize: '25px',
                backgroundColor: '#f9f9f7'
            }}>
                <div style={{
                    marginLeft: '3%',
                    padding: '1%'
                }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Home
                        </Link>
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/material-ui/getting-started/installation/"
                        >
                            Providers
                        </Link>
                        <Typography color="text.primary">Cab Services</Typography>
                    </Breadcrumbs>
                    <span>2245 matches found for : Cab Services In Florida</span>
                </div>
            </div>
            <Container maxWidth={"lg"}>
                <div style={{'marginTop': '3%'}}>
                    <Grid container spacing={5}>
                        {providers.map(provider => {
                            console.log(provider.provider.firstName)
                            return (
                                <Grid item xs={3.5}>
                                    <Card>
                                        <CardMedia
                                            sx={{height: 250}}
                                            image="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=600"
                                            title="green iguana"
                                        />
                                        <CardContent>
                                            <div>
                                                <span>{provider?.provider?.firstName} {provider.provider.lastName}(24, Male)</span>
                                            </div>
                                            <div style={{color: 'gray'}}>
                                                <span>Appliance Repairing</span>
                                            </div>
                                            <div>
                                            <span>
                                                $100 - $400
                                            </span>
                                            </div>
                                            <div>
                                                <Rating name="read-only" value={4} readOnly/>
                                            </div>
                                            <div style={{color: 'gray', fontSize: '15px'}}>
                                                <p>3 Years of Experience</p>
                                            </div>
                                            <div>
                                                <Button sx={{backgroundColor: 'darkorange'}} variant="contained"
                                                        onClick={() => setBookingDialogVisible(true)}>Book Now</Button>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                </div>
            </Container>
            <Container>
                <Dialog open={bookingDialogVisible}>
                    <DialogTitle>Proceed to Book</DialogTitle>
                    <DialogContent>
                        <div style={{marginTop:'1%'}}>
                            <Stack>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <div style={{marginTop:'5%'}}>
                                        <DateTimePicker
                                            label="Start Time"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                    <div style={{marginTop:'5%'}}>
                                        <DateTimePicker
                                            label="End Time"
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </div>
                                    <div style={{marginTop:'5%'}}>
                                        <label>Select Address</label>
                                        <div>
                                            <div>
                                                <Card>
                                            <CardContent>
                                                <div>
                                                    <div>
                                                        <span>Name</span>
                                                        <span>Kevin</span>
                                                    </div>
                                                    <div>
                                                        <span>Mobile</span>
                                                        <span>1234930230</span>
                                                    </div>
                                                    <div>
                                                        <span>Address</span>
                                                        <span>401, Chelsea Apt, Nehru Nagar, Kalyan</span>
                                                    </div>
                                                    <div>
                                                        <span>City</span>
                                                        <span>Mumbai</span>
                                                    </div>
                                                    <div>
                                                        <span>State</span>
                                                        <span>Maharashtra</span>
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </Card>
                                            </div>
                                            <div>
                                                <Card>
                                                    <CardContent>
                                                        <div>
                                                            <div>
                                                                <span>Name</span>
                                                                <span>Kevin</span>
                                                            </div>
                                                            <div>
                                                                <span>Mobile</span>
                                                                <span>1234930230</span>
                                                            </div>
                                                            <div>
                                                                <span>Address</span>
                                                                <span>401, Chelsea Apt, Nehru Nagar, Kalyan</span>
                                                            </div>
                                                            <div>
                                                                <span>City</span>
                                                                <span>Mumbai</span>
                                                            </div>
                                                            <div>
                                                                <span>State</span>
                                                                <span>Maharashtra</span>
                                                            </div>
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </LocalizationProvider>
                            </Stack>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button>Cancel</Button>
                        <Button>Proceed</Button>
                    </DialogActions>
                </Dialog>
            </Container>
            <Footer></Footer>
        </div>
    )
}

export default Providers
