import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OTPInput from "../../components/OtpInput";
import { useLocation, useNavigate } from "react-router-dom";
import UserService from "../../services/userService";
import Loader from "../../components/Loader";
import LoadingButton from "../../components/LoadingBtn";
import Notification from "../../components/Notification";
import { LocalStorageHelper } from "../../utils/helperFunctions";
import { LocalStorageKey } from "../../utils/constant";

const EmailVerify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [userEmail] = useState(location?.pathname?.split("/")?.[2]);
  const [otp,setOtp]=useState("")
  const [sendOTP, setSendOTP] = useState({
    isLoading: true,
    isOTPsendingError: false,
  });

  const sendEmailVerifyOtp = async () => {
    try {
      if (!userEmail) {
        setSendOTP({ ...sendOTP, isOTPsendingError: true, isLoading: false });
        return;
      }
      await UserService.getEmailVerifyOtp(userEmail);

      setSendOTP({ ...sendOTP, isOTPsendingError: false, isLoading: false });
    } catch (error) {
      setSendOTP({ ...sendOTP, isOTPsendingError: true, isLoading: false });
    }
  };

  useEffect(() => {
    sendEmailVerifyOtp();
  }, []);

  

  const verifyEmail = async () => {
    console.log(otp)
    try {
      if (!otp || otp.length !== 6) {
        return;
      }
      setLoading(true);
      const response = await UserService.verifyEmailOtp({ otp });
      if (response?.data?.succeeded && response?.data?.data) {
        const userData = LocalStorageHelper.getData(LocalStorageKey.USER_INFO);

        const updatedUserData = { ...userData, emailConfirmed: true };
        LocalStorageHelper.setData(LocalStorageKey.USER_INFO, updatedUserData);
        Notification("Email verified successfully");
        navigate("/provider");
      }else{
        Notification("Invalid OTP!",true);
      }
    } catch (error) {
      const errorMessage = error?.message || "failed to verify otp.";
      Notification(errorMessage, true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{}}>
      {sendOTP.isLoading ? (
        <Loader />
      ) : (
        <Card
          elevation={0}
          sx={{
            // width: 800,
            p: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {sendOTP.isOTPsendingError ? (
            <>
              <Typography variant="h5">
                Something went wrong while sending OTP.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h4">Verification</Typography>
              <Typography variant="body2">
                Please enter otp sent on {userEmail}
              </Typography>
              <Box>
                <OTPInput
                  key={"email-verify"}
                  handlerFunction={(otp)=>setOtp(otp)}
                  retryFunction={sendEmailVerifyOtp}
                />
              </Box>
              <Typography variant="body2" sx={{ py: 2 }}>
                Didn't receive OTP ?{" "}
              </Typography>
              <Typography variant="body2">
                Note :If you didn't receive OTP within 1 minute then kindly send
                email to{" "}
                <Box component="span" sx={{ color: "blue" }}>
                  support@stepstodigitech.com
                </Box>{" "}
                from your registered email address.
              </Typography>

              {isLoading ? (
                <LoadingButton />
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    mt: 4,
                    width: "40%",
                    textTransform: "none",
                    backgroundColor: "orange",
                    ":hover": {
                      backgroundColor: "#DB8809",
                    },
                  }}
                  onClick={verifyEmail}
                  disabled={otp.length!==6}
                >
                  Submit
                </Button>
              )}
            </>
          )}
        </Card>
      )}
    </Box>
  );
};

export default EmailVerify;
