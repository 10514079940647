import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Avatar, Box, Typography } from "@mui/material";
import SUBSCRIBE_LOGO from "../../assets/images/ic_subscribe.png";
import PROVIDER_LOGO from "../../assets/images/ic_service_provider.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";

const RegisterOptions = ({ open, setOpen, setOption }) => {
  const handleSelect = (opt) => {
    setOption(opt);
    setOpen(false);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/");
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      maxWidth="xs"
      fullWidth={true}
      aria-labelledby="register-options"
      sx={{ borderRadius: "20px" }}
    >
      <Box sx={{ display: "flex", justifyContent: "end", pr: 2, pt: 2 }}>
        <div onClick={handleClose}>
          <CancelIcon sx={{ cursor: "pointer" }} />
        </div>
      </Box>
      <DialogTitle
        id="register-options-title"
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          color: "orange",
        }}
      >
        <Typography sx={{fontSize:"2rem"}}>{"Sign up as"}</Typography>
      </DialogTitle>

      <DialogActions sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 1,
            cursor: "pointer",
          }}
          onClick={() => handleSelect("Subscriber")}
        >
          <Avatar alt="subscribe_logo"sx={{height:50,width:50}} src={SUBSCRIBE_LOGO} />
          <Typography variant="body1">Subscriber</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            p: 1,
            cursor: "pointer",
          }}
          onClick={() => handleSelect("Service Provider")}
        >
          <Avatar alt="provider_logo" sx={{height:50,width:50}} src={PROVIDER_LOGO} />

          <Typography>Service Provider</Typography>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default RegisterOptions;
