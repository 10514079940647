import { useQuery } from "@tanstack/react-query";
import Services from "../services/services";

export const useGetAllCategories = () => {
  const query = useQuery({
    queryKey: ["categories"],
    queryFn: Services.allCategories,
    retry: false,
    staleTime: 60000*5,//cache data for 5 min
  });
  return { ...query, data: query?.data?.data?.data };
};

export const useGetServicesByCategory = (categoryCode) => {
  const query = useQuery({
    queryKey: ["serrvicesByCategory", categoryCode],
    queryFn: () => Services.getServicesByCategory(categoryCode),
    retry: false,
    staleTime: 60000*5,//cache data for 5 min
  });
  return { ...query, data: query?.data?.data?.data };
};
