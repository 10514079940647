import React from "react";
import { Card, CardContent, Grid } from "@mui/material";
import "../assets/common.css";
function Refundpolicy() {
  return (
    <div>
    
      <div className="refnd" style={{ textAlign: "left" }}>
        <div className="loginscnd">
          <Grid container spacing={6}>
            <Grid item xs={12} lg={12}>
              <Card>
                <CardContent>
                  <div class="wpb_wrapper ">
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h2>Refund Policy</h2>
                        <h4>Eligibility for Refunds:</h4>
                        <p>
                          <strong>
                            We offer refunds on certain products or services
                            based on the following conditions:
                          </strong>
                        </p>
                        <ol>
                          <li>
                            The product or service is eligible for a refund as
                            specified in its respective terms and conditions or
                            description.
                          </li>
                          <li>
                            The refund request is made within the specified time
                            period, which is usually 7-10 days from the date of
                            purchase.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h4>Refunds Process:</h4>
                        <p>
                          To initiate a refund request, please contact our
                          customer support team through E-mail. Provide the
                          following details:
                        </p>
                        <ol>
                          <li>Order number or proof of purchase.</li>
                          <li>Reason for the refund request.</li>
                        </ol>
                        <h4>Refund Evaluation:</h4>
                        <p>
                          Once we receive your refund request, our team will
                          review it based on the eligibility criteria mentioned
                          above. We reserve the right to deny or approve refund
                          requests at our discretion.
                        </p>
                      </div>
                    </div>

                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h4>Refund Options:</h4>
                        <p>
                          <strong>
                            If your refund request is approved, we may offer one
                            or more of the following options:
                          </strong>
                        </p>
                        <ol>
                          <li>
                            Full refund: The entire purchase amount will be
                            refunded to the original payment method.
                          </li>
                          <li>
                            Partial refund: A portion of the purchase amount
                            will be refunded to the original payment method.
                          </li>
                        </ol>
                        <h4>Store credit:</h4>
                        <p>
                          The refunded amount will be credited to your account
                          for future purchases.
                        </p>
                        <h4>Product exchange:</h4>
                        <p>
                          We may offer to exchange the product with an
                          alternative item of equal value.
                        </p>
                        <h4>Refund Timeframe:</h4>
                        <ol>
                          <li>
                            Once your refund request is approved, we will
                            process the refund within 7-10 days.
                          </li>
                          <li>
                            Please note that the time it takes for the refunded
                            amount to appear in your account may vary depending
                            on your payment provider.
                          </li>
                        </ol>
                      </div>
                    </div>

                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h4>Non-Refundable Items:</h4>
                        <p>
                          <strong>
                            The following items or services are generally not
                            eligible for refunds:
                          </strong>
                        </p>
                        <ol>
                          <li>
                            Downloadable digital products (e-books, software,
                            etc.) once the download or access link has been
                            provided.
                          </li>
                          <li>
                            Services that have been fully rendered or completed.
                          </li>
                          <li>Customized or personalized products.</li>
                          <li>Gift cards or vouchers.</li>
                        </ol>
                      </div>
                    </div>

                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h4>Damaged or Defective Products:</h4>
                        <p>
                          If you receive a damaged or defective product, please
                          contact our customer support team immediately. We will
                          arrange for a replacement or refund, depending on the
                          circumstances.
                        </p>
                        <h4>Changes to the Refund Policy:</h4>
                        <p>
                          We reserve the right to modify or update our refund
                          policy at any time. Any changes will be effective
                          immediately upon posting the updated policy on our
                          website.
                        </p>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default Refundpolicy;
