import axios from "axios";
import {LocalStorageHelper} from "../utils/helperFunctions"
import { ProviderAPIConfiguration } from "../utils/API";
const staticuri = 'https://servicesonclick-admin.azurewebsites.net';

const ProviderService = {
  fetchProviderList: function (subCategoryCode) {
    return axios.get(
      staticuri + "/api/provider-services/list?serviceCode=" + subCategoryCode,
      {}
    );
  },

  fetchInactiveProviders: function () {
    const Token=LocalStorageHelper.getToken()?.Token;
    return axios
      .get(staticuri + "/api/provider/inactive/list", {
        headers: {
          Authorization: "Bearer " + Token + "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        return response.data.data;
      });
  },

  fetchInactiveProviderServices: function () {
    const Token=LocalStorageHelper.getToken()?.Token;
    return axios
      .get(staticuri + "/api/provider-services/inactive/list", {
        headers: {
          Authorization: "Bearer " + Token + "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        return response.data.data;
      });
  },

  updateAddressProofDocument: async function (documentId, isApprove) {
    const data = { documentId: documentId.toString() };
    const Token=LocalStorageHelper.getToken()?.Token;
    return axios
      .put(
        staticuri +
          "/api/document/approve-reject/address?documentId=" +
          documentId +
          "&isApprove=" +
          isApprove,
        { data },
        {
          headers: {
            Authorization:
              "Bearer " + Token + "",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        //console.log(err);
      });
  },

  updateIdentityProofDocument: async function (documentId, isApprove) {
    const data = { documentId: documentId.toString() };
    const Token=LocalStorageHelper.getToken()?.Token;
    return axios
      .put(
        staticuri +
          "/api/document/approve-reject/identity?documentId=" +
          documentId +
          "&isApprove=" +
          isApprove,
        { data },
        {
          headers: {
            Authorization:
              "Bearer " + Token + "",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  updateWorkAuthorizationDocument: async function (documentId, isApprove) {
    debugger
    const data = { documentId: documentId.toString() };
    const Token=LocalStorageHelper.getToken()?.Token;
    return axios
      .put(
        staticuri +
          "/api/document/approve-reject/work-auth?documentId=" +
          documentId +
          "&isApprove=" +
          isApprove,
        { data },
        {
          headers: {
            Authorization:
              "Bearer " + Token + "",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  fetchAddressKycDocumentDetails: async (providerEmail) => {
    const Token=LocalStorageHelper.getToken()?.Token;
    const response = await axios.get(
      staticuri +
        "/api/document/list/address?providerEmail=" +
        providerEmail,
      {
        headers: {
          Authorization: "Bearer " + Token + "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data.data;
  },

  fetchIdentityKycDocumentDetails: async (providerEmail) => {
    const Token=LocalStorageHelper.getToken()?.Token;
    const response = await axios.get(
      staticuri +
        "/api/document/list/identity?providerEmail=" +
        providerEmail,
      {
        headers: {
          Authorization: "Bearer " + Token + "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data.data;
  },

  fetchWorkKycDocumentDetails: async (providerEmail, documentId) => {
    debugger;    
    const Token=LocalStorageHelper.getToken()?.Token;
    const response = await axios.get(
      staticuri +
        "/api/document/list/work-auth?providerEmail=" +
        providerEmail +
        "&documentid=" +        
        documentId.toString() ,
      {
        headers: {
          Authorization: "Bearer " + Token + "",
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data.data;
  },
  getProviderAppointments:async()=>{
    const token=LocalStorageHelper.getJWTToken();
    return await axios.get(ProviderAPIConfiguration.getAllAppointments,{
      headers: {
        "Content-Type": "application/json",
        "Accept": "*/*",
        "Authorization":`Bearer ${token}`
      }
    });
  }
};

export default ProviderService;