import React, { useEffect, useRef, useState } from "react";
import RegisterOptions from "./RegisterOptions";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CustomPhoneInput from "../../components/PhoneInput";
import { LocalStorageKey, UserTypes, emailRegex } from "../../utils/constant";
import OTPInput from "../../components/OtpInput";
import UserService from "../../services/userService";
import Notification from "../../components/Notification";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LoadingButton from "../../components/LoadingBtn";
import {
  LocalStorageHelper,
  getLongitudeAndLatitude,
} from "../../utils/helperFunctions";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoginPopupOpen } from "../../Redux/slice/common";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Register = () => {
  const dispatch=useDispatch();
  const [registerOptionsOpen, setRegisterOptionsOpen] = useState(true);
  const profileImageRef = useRef(null);
  const [signupAs, setSignUpAs] = useState(null);
  const [countryCode, setCountryCode] = useState("+91");
  const [condition, setCondition] = useState({
    termsCondition: false,
    reciveMessage: false,
  });
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    showMobileOtp: false,
    showEmailOtp: false,
    isEmailVerifyLoading: false,
    confirmPasswordAndPasswordMatched: true,
    isRegisterLoading: false,
    previewProfileImage: null,
    isInvalidFirstName: false,
    isInvalidLastName: false,
    isOtpSentloading:false,
    isEmailAvailable:false,
    isEmailChecked:false,
    isPasswordVisible:false,
    isConformPasswordVisisble:false

  });

  const [userData, setUserData] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    MobileNumber: "",
    UserRoleCode: "",
    Password: "",
    ConfirmPassword: "",
    Latitude: "",
    Longitude: "",
    MobileCountryCode: "",
    IsMobileVerified: false,
    IsEmailVerified: false,
    ProfilePhoto: "",
  });

  const [isRegisterBtnDisabled, setisRegisterBtnDisabled] = useState(true);
  useEffect(() => {
    const userRole =signupAs === "Subscriber" ? UserTypes.SUBSCRIBER : UserTypes.PROVIDER;
    setUserData({ ...userData, UserRoleCode: userRole });
    window.scroll(0, 0);
  }, [signupAs]);

  const inputChangeHandler = (e) => {
    const key = e?.target?.name;
    let value = e?.target?.value;

    //profile image change
    if (key === "ProfilePhoto") {
      value = e.target.files[0];
      if (e.target.files.length > 0) {
        let previewSrc = URL.createObjectURL(e.target.files[0]);
        setFormState({ ...formState, previewProfileImage: previewSrc });
      }
    }

    //check email  is valid email and if valid then verify if
    if (key === "Email" && emailRegex.test(value)) {
      handleEmailVerify(value);
    }
    if (key === "FirstName" || key === "LastName") {
      const isInvalid = value?.length < 3;
      key === "FirstName"
        ? setFormState({ ...formState, isInvalidFirstName: isInvalid })
        : setFormState({ ...formState, isInvalidLastName: isInvalid });
    }

    setUserData((userData) => ({ ...userData, [key]: value }));

    //check password and confirm password matched
    if (key === "ConfirmPassword") {
      const areMatched = userData?.Password === value;
      setFormState({
        ...formState,
        confirmPasswordAndPasswordMatched: areMatched,
      });
    }

    // Check if registration button should be disabled based on validation
    const isRegisterBtnDisabled = !handleValidation({
      ...userData,
      [key]: value,
    });
    setisRegisterBtnDisabled(isRegisterBtnDisabled);
  };

  // Function to validate user data
  const handleValidation = (userData) => {
    // Check if all required fields are present and not empty
    return (
      userData?.FirstName &&
      userData?.LastName &&
      userData?.Email &&
      userData?.Password &&
      userData?.ConfirmPassword &&
      userData?.UserRoleCode &&
      userData?.MobileNumber
    );
  };

  //this function check if phone number is already register or not
  const handlePhoneNumberVerify = async () => {
    if (!countryCode || !userData?.MobileNumber) {
      Notification("Please provide valid mobile number", true);
      return
    }
    setUserData({ ...userData, MobileCountryCode: countryCode });
    const mobileNumber = countryCode + userData?.MobileNumber;
    setFormState({...formState,isOtpSentloading:true});
    const response = await UserService.checkMobileNumberAvailable(mobileNumber);
    if (!response?.verified) {
      Notification(response.message, true);
      setFormState({...formState,isOtpSentloading:false});
      return;
    }
    setFormState({...formState,isOtpSentloading:false});
    //now mobile number is avialable proceed to sent opt
    sendMobileOpt();
  };

  //this function sends otp to users mobile number
  const sendMobileOpt = async () => {
    const otpResponse = await UserService.sendMobileVerificationOpt(countryCode+userData?.MobileNumber,countryCode);
    if (!otpResponse.success) {
      Notification(otpResponse.message, true);
      return;
    }
    Notification(otpResponse.message);
    setFormState({ ...formState, showMobileOtp: true });
    return;
  };

  //this function verify mobile otp
  const verifyMobileOtp = async (otp) => {
    const response = await UserService.verifyMobileNumberOpt(otp,countryCode+userData.MobileNumber);
    if (!response.success) {
      Notification(response?.message, true);
      return;
    }
    Notification(response.message);
    setFormState({ ...formState, showMobileOtp: false });
    setUserData({ ...userData, IsMobileVerified: true });
  };

  //this function check if email is already register or not
  const handleEmailVerify = async (email) => {
    setFormState({ ...formState, isEmailVerifyLoading: true });
    const response = await UserService.checkEmailAvailable(email);
    if (response?.error) {
      Notification(response.message, true);
      setFormState({...formState,isEmailVerifyLoading: false});
      return;
    } else {
      Notification(response.message);
      // setUserData((prevState) => ({ ...prevState, IsEmailVerified: false }));
    }
   setFormState({...formState,isEmailAvailable:true,isEmailChecked:true,isEmailVerifyLoading: false});
  };

  //this function trigger when click on register btn
  const handleRegister = async () => {
    if (!formState.isEmailChecked) {
      Notification("Email is invalid or already registered.", true);
      return;
    }

    if (!userData?.IsMobileVerified) {
      Notification("Please verify mobile number.", true);
      return;
    }

    if(userData.ConfirmPassword!==userData.Password){
      setFormState({...formState,confirmPasswordAndPasswordMatched:false});
      return
    }else{
      setFormState({...formState,confirmPasswordAndPasswordMatched:true});
    }

    const {confirmPasswordAndPasswordMatched,isInvalidFirstName,isInvalidLastName} = formState;
    if (!confirmPasswordAndPasswordMatched || isInvalidFirstName || isInvalidLastName) {
      return;
    }

    const { latitude, longitude } = await getLongitudeAndLatitude();
    setFormState({ ...formState, isRegisterLoading: true });
    const response = await UserService.registerUser({...userData,Longitude: longitude,Latitude: latitude,MobileNumber:countryCode+userData.MobileNumber });
    if (response.error) {
      Notification(response.message, true);
    } else {
      Notification(response.message);
      LocalStorageHelper.setData(
        LocalStorageKey.USER_INFO,
        response?.data?.tokens
      );
    }
    setFormState({ ...formState, isRegisterLoading: false });
    navigateAfterRegister();
  };
  
  //this function to handle navigate after successfull register according to user type
  const navigateAfterRegister = () => {
    const userType = LocalStorageHelper.getToken()?.userType;
    switch (userType) {
      case UserTypes.SUPERADMIN:
        navigate("/profile");
        break;
      case UserTypes.PROVIDER:
        navigate("/provider");
        break;
      case UserTypes.SUBSCRIBER:
        navigate("/subsciber");
        break;
    }
  };
    //function to handle password visibility icons
    const handlePassowrdVisibilityToggle = (inputfield) => {
      if (inputfield === "Password") {
        setFormState({
          ...formState,
          isPasswordVisible: !formState.isPasswordVisible,
        });
      } else {
        setFormState({
          ...formState,
          isConformPasswordVisisble: !formState.isConformPasswordVisisble,
        });
      }
   
  };
  return (
    <div>
      <RegisterOptions
        open={registerOptionsOpen}
        setOpen={() => setRegisterOptionsOpen((prev) => !prev)}
        setOption={setSignUpAs}
      />
     
        {/**form  starts*/}
        <Container
          maxWidth="sm"
          sx={{ boxShadow: 4, px: 2, py: 2,mt:2, borderRadius: "15px" }}
        >
           <Box sx={{display:"flex",justifyContent:"center",mb:1}}>
          {signupAs && (
            <Typography variant="h5" fontWeight={"bold"}>
              {" "}
              Sign Up as <span style={{ color: "orange" }}>{signupAs}</span>
            </Typography>
          )}
        </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
            <div
              style={{
                position: "relative",
              }}
            >
              <input
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                ref={profileImageRef}
                name="ProfilePhoto"
                onChange={(e) => inputChangeHandler(e)}
              ></input>

              <Avatar
                src={formState.previewProfileImage}
                sx={{
                  width: 80,
                  height: 80,
                  objectFit: "contain",
                  boxShadow: 5,
                }}
              />

              <div onClick={() => profileImageRef.current.click()}>
                <PhotoCameraIcon
                  sx={{
                    position: "absolute",
                    top: 58,
                    right: 0,
                    color: "orange",
                    fontSize: "2rem",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                />
              </div>
            </div>
          </Box>

          <TextField
            sx={{ width: "100%", mt: 1 }}
            label={"First Name"}
            name="FirstName"
            value={userData?.FirstName}
            onChange={(e) => inputChangeHandler(e)}
          ></TextField>
          {formState.isInvalidFirstName && (
            <ErrorMessage
              message={"First name should have at least 3 characters."}
            />
          )}
          <TextField
            sx={{ width: "100%", mt: 1 }}
            label={"Last Name"}
            name="LastName"
            value={userData.LastName}
            onChange={(e) => inputChangeHandler(e)}
          ></TextField>
          {formState.isInvalidLastName && (
            <ErrorMessage
              message={"Last name should have at least 3 characters."}
            />
          )}
          <Box sx={{ position: "relative" }}>
            <TextField
              sx={{ width: "100%", mt: 1 }}
              label={"Email"}
              type="email"
              name="Email"
              value={userData.Email}
              disabled={
                formState.isEmailChecked || formState.isEmailVerifyLoading
              }
              onChange={(e) => inputChangeHandler(e)}
            ></TextField>
            {formState.isEmailAvailable && (
              <CheckCircleOutlineIcon
                sx={{
                  color: "green",
                  position: "absolute",
                  right: 8,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
              />)}
              {formState.isEmailVerifyLoading && (
                <CircularProgress
                  size={30}
                  sx={{
                    color: "green",
                    position: "absolute",
                    right: 8,
                    top: "30%",
                    transform: "translateY(-50%)",
                    zIndex: 1,
                    fontSize: "0.8rem",
                  }}
                />
              )}
          </Box>

          {formState.showMobileOtp ? (
            <OTPInput
              handlerFunction={verifyMobileOtp}
              retryFunction={sendMobileOpt}
            />
          ) : (
            <Box sx={{ position: "relative" }}>
              <CustomPhoneInput
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                value={userData?.MobileNumber}
                name="MobileNumber"
                onChange={inputChangeHandler}
                disabled={userData.IsMobileVerified}
                type="number"
              />
              <Button
                sx={{
                  color: "green",
                  position: "absolute",
                  right: 8,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  fontSize: "0.8rem",
                  textTransform: "none",
                }}
                disabled={userData.IsMobileVerified}
                onClick={() => handlePhoneNumberVerify()}
              >
                {userData.IsMobileVerified ? (
                  <span style={{ color: "green" }}>Verified</span>
                ) : (
                  formState.isOtpSentloading ?<CircularProgress size={25}/> :"Verify"
                )}
              </Button>
            </Box>
          )}

            <TextField
            sx={{ width: "100%", mt: 1 }}
            label={"Password"}
            name="Password"
            type={formState?.isPasswordVisible ? "text":"password"}
            value={userData.Password}
            onChange={(e) => inputChangeHandler(e)}
            InputProps={{
              endAdornment: (
                <>
                  {formState?.isPasswordVisible ? (
                    <VisibilityOffIcon
                      onClick={()=>handlePassowrdVisibilityToggle("Password")}
                      sx={{ cursor: "pointer",color:"orange"  }}
                    />
                  ) : (
                    <VisibilityIcon
                    onClick={()=>handlePassowrdVisibilityToggle("Password")}
                      sx={{ cursor: "pointer",color:"orange"  }}
                    />
                  )}
                </>
              )
            }}
          >
          </TextField>
          
          <TextField
            sx={{ width: "100%", mt: 1 }}
            label={"Confirm Password"}
            name="ConfirmPassword"
            type={formState?.isConformPasswordVisisble ? "text" :"password"}
            value={userData.ConfirmPassword}
            onChange={(e) => inputChangeHandler(e)}
            InputProps={{
              endAdornment: (
                <>
                  {formState?.isConformPasswordVisisble ? (
                    <VisibilityOffIcon
                      onClick={()=>handlePassowrdVisibilityToggle("ConfirmPassword")}
                      sx={{ cursor: "pointer",color:"orange" }}
                    />
                  ) : (
                    <VisibilityIcon
                    onClick={()=>handlePassowrdVisibilityToggle("ConfirmPassword")}
                      sx={{ cursor: "pointer",color:"orange"  }}
                    />
                  )}
                </>
              )
            }}
          ></TextField>
          {!formState.confirmPasswordAndPasswordMatched &&
            userData?.Password && (
              <ErrorMessage
                message={"Password & confirm password are not matching"}
              />
            )}

          {/**terms/condition */}
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Checkbox
              value={condition.termsCondition}
              onChange={(e) =>
                setCondition((prevState) => ({
                  ...prevState,
                  termsCondition: !prevState.termsCondition,
                }))
              }
            />
            <Typography>Terms & conditions</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Checkbox
              value={condition.reciveMessage}
              onChange={(e) =>
                setCondition((prevState) => ({
                  ...prevState,
                  reciveMessage: !prevState.reciveMessage,
                }))
              }
            />
            <Typography>Concent for receiving text (SMS)</Typography>
          </Box>
          {/**terms/condition */}

          {/**register button */}
          <Box sx={{ display: "flex", justifyContent: "center",flexDirection:"column",alignItems:"center",gap:2 }}>
            {formState.isRegisterLoading ? (
              <LoadingButton />
            ) : (
              <Button
                sx={{ width: "60%" }}
                variant="contained"
                disabled={isRegisterBtnDisabled || !condition?.termsCondition}
                onClick={handleRegister}
              >
                Register
              </Button>
            )}
            <Link to="/" style={{textDecoration:"none"}} onClick={()=>dispatch(setLoginPopupOpen())}>Already have an account ? Click here </Link>
          </Box>
          {/**register button */}
        </Container>
     
      {/**form  end*/}
    </div>
  );
};

export default Register;

const ErrorMessage = ({ message }) => {
  return <span style={{ fontSize: "0.8rem", color: "red" }}>{message}</span>;
};
