import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import {Avatar, Link, Menu, Tooltip} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import nvbarr from "./navbar.css";
import logo from './images/serviceonclicklogo.png';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function logout(){
    localStorage.removeItem('username');
localStorage.removeItem('password');
localStorage.clear();
// you can also like localStorage.removeItem('Token');
 window.location.href = "/login";
}

function conditionalRender(){
    if(localStorage.getItem('username') !== null) {
       return  <a style={{textDecoration:'none',color:'#2463b5', cursor: 'pointer'}} onClick={logout} >
       Logout
   </a>;
    }
    else{
       return <a style={{textDecoration:'none'}}  href="/login" >
       Login
   </a>;
    }
 };

export default function SearchAppBar() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar className="nvbar" style={{position:'relative'}}>
                <Toolbar>
                <Link href="/">
      <Box className="logoimg"
        component="img"        
        alt="Service"
        src={logo}
      />
    </Link>    <Box sx={{ display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        ml:'auto',
                    }}>  
                                         
                            { conditionalRender() }            
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}