import { createSlice } from "@reduxjs/toolkit";

const initialAppState = {
  isLoginPopupOpen: false,
  category: {},
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialAppState,
  reducers: {
    setLoginPopupOpen: (state) => {
      state.isLoginPopupOpen = !state.isLoginPopupOpen;
    },
    setCurrentCategory: (state, action) => {
      state.category = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoginPopupOpen, setCurrentCategory } = appSlice.actions;

export default appSlice.reducer;
