import React, {useEffect, useState} from "react";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import Container from "@mui/material/Container";
import { useNavigate } from 'react-router-dom';
import {Alert, Card, CardContent, FormControl, Grid, InputLabel, Snackbar, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Profilemid from "../profile/profilemid";

function Profile(props){
    
       return (   
        <>
   
         <div className="profilecontainer">                               
                <Container>                    
                    <Profilemid/>
                </Container>               
            </div>
            
            </>
    )

    }
export default Profile;