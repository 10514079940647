import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import CallIcon from "@mui/icons-material/Call";

const ContactNumber = ({ contactNumber }) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyContactNumber = () => {
    console.log(contactNumber)
    const textarea = document.createElement("textarea");
    textarea.value = contactNumber ?? "";
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setIsCopied(true);
  };

  const handleMouseEnter = () => {
    if (isCopied) {
      // Reset the copied state if user hovers over the icon again
      setIsCopied(false);
    }
  };
  return (
    <Tooltip title={isCopied ? "Copied" : "Copy Contact Number"}>
      <CallIcon
        sx={{ color: "navy", mr: 1, cursor: "pointer" }}
        onClick={copyContactNumber}
        onMouseEnter={handleMouseEnter}
      />
    </Tooltip>
  );
};

export default ContactNumber;
