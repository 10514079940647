import React from "react";
import { Skeleton } from "@mui/material";

const CardSkeleton = ({height=200}) => {
  return (
    <Skeleton
      variant="rectangular"
      width={"100%"}
      height={height}
      sx={{ bgcolor: "grey.400", borderRadius: "10px" }}
    />
  );
};

export default CardSkeleton;
