import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { LocalStorageHelper } from "../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";

const Provider = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const userData = LocalStorageHelper.getUserData();
  const [isEmailVerifyDialogOpen, setEmailVerifyDialogOpen] = useState(false);

  useEffect(() => {
    if (!userData.emailConfirmed) {
      setEmailVerifyDialogOpen(true);
    }
  }, []);

  const navigate = useNavigate();

  const handleClose = () => {
    setEmailVerifyDialogOpen(!isEmailVerifyDialogOpen);
    navigate(`/email-verify/${userData.email}`);
  };

  const renderCompleteProfileCard = (
    <Card
      sx={{
        // width: 800,
        p: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5">
        Please complete your profile to get Buisness
      </Typography>
      <Button
        variant="contained"
        sx={{
          mt: 4,
          textTransform: "none",
          backgroundColor: "orange",
          ":hover": {
            backgroundColor: "#DB8809",
          },
        }}
      >
        Click here to complete
      </Button>
    </Card>
  );

  const renderEmailVerifyPopup = (
    <Dialog
      open={isEmailVerifyDialogOpen}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      sx={{ display: "flex", justifyContent: "center", alignItems: "centers" }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {`Hey ${userData?.firstName} ${userData?.lastName}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your email{" "}
          <Box component="span" sx={{ color: "blue" }}>
            {userData?.email}
          </Box>{" "}
          is not verified to continue using app please verify now.
        </DialogContentText>
        <DialogContentText id="alert-dialog-description"></DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={handleClose}
          variant="contained"
          sx={{ width: "50%", my: 2, textTransform: "none" }}
        >
          Verify Now
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 5,
      }}
    >
      {renderCompleteProfileCard}

      {renderEmailVerifyPopup}
    </Box>
  );
};

export default Provider;
