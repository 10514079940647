import React, { useEffect, useState } from "react";
import {
  Card,
  CardMedia,
  Divider,
  IconButton,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";

export default function DocumentApproveDialog({open,onCancel,onConfirm,details,loading,title,}) {
  const [openedDocument, setOpenedDocument] = useState({});
  const [currDocIdx, setCurrDocIdx] = useState(0);

  useEffect(() => {
    if (details?.length > 1) {
      setOpenedDocument(details[currDocIdx]);
    } else {
      setOpenedDocument(details[0]);
    }
    
  }, [currDocIdx, details]);
 

  // if has multiple documents open according to current index
  const handleDocumentChange = (direction) => {
    setCurrDocIdx((prevIdx) => {
      let newIdx = prevIdx;
      if (direction === "forward" && prevIdx < details.length - 1) {
        newIdx += 1;
      } else if (direction === "backward" && prevIdx > 0) {
        newIdx -= 1;
      }
      return newIdx;
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      //   maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="bold" variant="h5">
            {title || "Document"}
          </Typography>
          <Stack direction="row" justifyContent="end" alignItems="center">
            {details?.length > 1 && (
              <Stack direction="row" alignItems="center">
                <IconButton onClick={() => handleDocumentChange("backward")}>
                  <ArrowBackIosNewIcon />
                </IconButton>
                <Typography variant="body2">
                  {currDocIdx + 1} / {details.length}
                </Typography>
                <IconButton onClick={() => handleDocumentChange("forward")}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Stack>
            )}
          </Stack>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider sx={{ border: "1px solid gray" }} />
      <DialogContent>
        <Card
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
            width: 500,
            height: 350,
          }}
        >
          <ImageWithSkeleton
            key={openedDocument?.documentUrl}
            src={openedDocument?.documentUrl}
            alt="Document Image"
            height={380}
            width="100%"
            isFetching={loading}
          />
        </Card>
      </DialogContent>
      <Divider sx={{ border: "1px solid gray" }} />
      <DialogActions sx={{ mr: 2, mb: 2 }}>
        <Button
          variant="outlined"
          sx={{ textTransform: "none" }}
          disabled={!openedDocument?.isApproved}
          onClick={() =>
            onConfirm({
              id: openedDocument?.documentId,
              type: title,
              isApprove: false,
            })
          }
        >
          Reject
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          disabled={openedDocument?.isApproved}
          onClick={() =>
            onConfirm({
              id: openedDocument?.documentId,
              type: title,
              isApprove: true,
            })
          }
        >
          Approve
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// componenet to render image with skeleton
const ImageWithSkeleton = ({ src, alt, height, width, isFetching }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    setIsLoading(false); 
  };

  return (
    <div style={{ position: "relative", height, width }}>
      {(isLoading || isFetching) && (
        <Skeleton
          variant="rectangular"
          height={height}
          width={width}
          style={{ position: "absolute", top: 0, left: 0 }}
        />
      )}
      <CardMedia
        component="img"
        height={height}
        width={width}
        src={src}
        alt={alt}
        sx={{
          objectFit: "cover",
          visibility: isLoading ? "hidden" : "visible",
        }}
        onLoad={handleImageLoad}
        onError={handleImageError}
      />
    </div>
  );
};
