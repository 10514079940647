import React, { useEffect, useState } from "react";
import ProviderService from "../../../services/provider-service";
import { DataGrid } from "@mui/x-data-grid";
import {
  Avatar,
  Chip,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DocumentApproveDialog from "./DocumentApproveDialog";
import toast from "react-hot-toast";

const WorkAuthApproval = () => {
  const [, setProviderWorkList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [tableCols, setTableCols] = useState([]);
  const [isfetchigDocument, setFetchigDocument] = useState(true);
  const [documentData, setDocumentData] = useState([]);
  const [dialogTilte, setDialogTitle] = useState("");
  const [isApproveDialgOpen, setApproveDialogOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  //  api call for fetch work approval list
  const fetchWorkAuthApprovalList = async () => {
    try {
      setLoading(true);
      const servicesList =
        await ProviderService.fetchInactiveProviderServices();
      if (servicesList && servicesList.length) {
        const updatedList = servicesList.map((s) => {
          return {
            id: s.service.providerServiceId,
            userId: s.provider.userId,
            providerName: s.provider.firstName + " " + s.provider.lastName,
            email: s.provider.email,
            serviceId: s.service.providerServiceId,
            serviceName: s.service.service?.name,
            serviceImgUrl: s.service?.service?.imageUri,
            serviceCategoryImgUrl: s.service?.serviceCategory?.imageUri,
            serviceCategory: s.service.serviceCategory?.name,
            isApproved: s.service.isServiceApproved,
            isWorkProofApproved: s.service.isWorkkycProofApproved,
            documentId: s.service?.workKYCDocumeentId,
          };
        });

        setProviderWorkList(updatedList);
        setTableRows(updatedList);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkAuthApprovalList();
  }, []);

  // set coulumns when there is change in rows
  useEffect(() => {
    const columns = [
      {
        field: "providerName",
        headerName: "Provider Name",
        width: 150,
      },
      {
        field: "serviceName",
        headerName: "Service Name",
        width: 350,
        renderCell: (params) => (
          <Stack
            key={params?.row?.id}
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Avatar
              src={params?.row?.serviceImgUrl}
              alt="service"
              sx={{ height: 30, width: 30 }}
            />
            <Typography>{params?.row?.serviceName}</Typography>
          </Stack>
        ),
      },
      {
        field: "serviceCategory",
        headerName: "Service Category",
        width: 250,
        // headerAlign: "center",
        // align: "center",
        renderCell: (params) => (
          <Stack
            key={params?.row?.id}
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Avatar
              src={params?.row?.serviceCategoryImgUrl}
              alt="category"
              sx={{ height: 30, width: 30 }}
            />
            <Typography>{params?.row?.serviceCategory}</Typography>
          </Stack>
        ),
      },
      {
        field: "Address Proof",
        headerName: "Work Authorisation",
        width: 140,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Chip
            label="view"
            icon={<VisibilityIcon sx={{ color: "#2c64b8" }} />}
            onClick={() => {
              const providerEmail = params?.row?.email;
              const documentId = params?.row?.documentId;
              fetchDocmentdetails(providerEmail, documentId);
              setApproveDialogOpen(true);
            }}
          />
        ),
      },
      {
        field: "isAddressProofApproved",
        headerName: "Status",
        width: 100,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Chip
            variant="filled"
            sx={{ width: "80px" }}
            label={params?.row?.isWorkProofApproved ? "Approved" : "Pending"}
            color={params?.row?.isWorkProofApproved ? "success" : "warning"}
            size="small"
          />
        ),
      },
    ];

    setTableCols(columns);
  }, [setTableRows, tableRows]);

  // api call for get service uploaded document
  const fetchDocmentdetails = async (providerEmail, documentId) => {
    try {
      if (!providerEmail) return;
      setDocumentData({});
      setFetchigDocument(true);
      setDialogTitle("Work Authorization Proof");
      let response = [];

      response = await ProviderService.fetchWorkKycDocumentDetails(
        providerEmail,
        documentId
      );

      if (response && response?.length) {
        setDocumentData(response);
      }
      setFetchigDocument(false);
    } catch (error) {
      setFetchigDocument(false);
    }
  };

  //  api call for approve or reject service
  const hanldleAprroveDocument = async (document) => {
    setApproveDialogOpen(!isApproveDialgOpen);
    try {
      const { id, isApprove } = document;
      setLoading(true);
      const res = await ProviderService.updateWorkAuthorizationDocument(
        id,
        isApprove
      );
      if (res?.data?.data && res?.data?.succeeded) {
        await fetchWorkAuthApprovalList();
        toast.success("Update Success !");
      }
    } catch (error) {
      toast.success("Update Failed !");
    }
  };

  //  loading state
  if (isLoading) {
    return (
      <Stack justifyContent="center" alignItems="center" sx={{height:"20vh"}}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <div>
      {/**open dialog when click on view */}
      <DocumentApproveDialog
        open={isApproveDialgOpen}
        onCancel={() => setApproveDialogOpen(!isApproveDialgOpen)}
        onConfirm={hanldleAprroveDocument}
        details={documentData}
        loading={isfetchigDocument}
        title={dialogTilte}
      />

      <DataGrid
        rows={tableRows}
        columns={tableCols}
        pageSize={5}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 5, page: 0 },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
      />
    </div>
  );
};

export default WorkAuthApproval;
