import axios from "axios";
import { LocalStorageHelper } from "../utils/helperFunctions";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const token = LocalStorageHelper.getJWTToken('token');

    if (!token) {
      throw new Error('Token is expired.');
    }
    // add token request is sent
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error)
)

axiosInstance.interceptors.response.use(
  function (response) {
    // const { status, data, statusText } = response;
    // if (status === 200 && data.succeeded && statusText === "OK") {
    //   return {
    //     success: true,
    //     status: status,
    //     data: data?.data,
    //   };
    // }
    // return {
    //   success: false,
    //   status: status,
    //   data: data,
    //   message:data?.errors?.[0]?.description || "Internal server error"
    // };
    // if (status === 200 && data.succeeded && statusText === "OK") {
    if(!response?.data || !response.data?.succeeded){
      throw new Error("Request was not successful");
    }
    return response;
  },
  function (error) {
    return {
      success: false,
      message: error?.message ?? error?.response?.message ?? "Internal server error",
      status: error?.response?.status ?? 500,
    };
  }
);

export default axiosInstance;
