import React from "react";
import {Alert, Card, CardContent, FormControl, Grid, InputLabel, Snackbar, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

class CustomerRegistration extends React.Component {

    render() {
        return (
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={4}>
                        <Card>
                            <CardContent>
                                <Box
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%',
                                        mt:'5%'
                                    }}
                                >
                                    <TextField fullWidth label="Mobile Number" id="fullWidth" />
                                </Box>

                                <Box
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%',
                                        mt:'5%'
                                    }}
                                >
                                    <TextField fullWidth label="Password" id="fullWidth" type="password"/>
                                </Box>

                                <Box
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%',
                                        mt:'5%',
                                        fontSize:'12px'
                                    }}
                                >
                                    <span>By continuing, I agree to terms of use and privacy policy</span>
                                </Box>
                                <Box
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%',
                                        mt:'5%',
                                        fontSize:'12px'
                                    }}
                                >
                                    <span>Not Registered? <a href="">Sign Up!</a></span>
                                </Box>
                                <Box
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%',
                                        mt:'5%'
                                    }}
                                >
                                    <Button variant="contained"
                                            onClick={()=>
                                                this.loginUser("sumitpatel","Sumit@2022")}>
                                        Login
                                    </Button>
                                </Box>

                                <Box
                                    sx={{
                                        width: 500,
                                        maxWidth: '100%',
                                        mt:'5%',
                                        fontSize:'12px'
                                    }}
                                >
                                    <span>Have trouble logging in? <a href="">Get Help</a></span>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default CustomerRegistration;