import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import ProviderService from "../../../services/provider-service";
import { Chip, CircularProgress, Stack } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DocumentApproveDialog from "./DocumentApproveDialog";
import toast from "react-hot-toast";
import { capitalizeFirstLetter } from "../../../utils/helperFunctions";

export default function KycApprovalList() {
  const [, setProviderList] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [tableCols, setTableCols] = useState([]);
  const [isApproveDialgOpen, setApproveDialogOpen] = useState(false);
  const [dialogTilte, setDialogTitle] = useState("");
  const [isfetchingData, setFetchingData] = useState(true);
  const [isfetchigDocument, setFetchigDocument] = useState(true);
  const [documentData, setDocumentData] = useState([]);

  //api call to fetach all table data (kyc approval list)
  const fetchProviderKYCApprovalList = async () => {
    try {
      setFetchingData(true);
      const ListOfProviders = await ProviderService.fetchInactiveProviders();
      if (ListOfProviders) {
        //filter :: remove superadmin entry by email id
        const filteredList=ListOfProviders?.filter((p)=>p.email!=="superadmin@stepstodigitech.com") || []
        setProviderList(filteredList);
        const rows = filteredList?.map((d) => ({
            id: d.userId,
            providerName: d.firstName + " " + d.lastName,
            ...d,
          })) || [];
        setTableRows(rows);
      }
      setFetchingData(false);
    } catch (error) {
      toast.error(error?.message || "Something went wrong !");
      setFetchingData(false);
    }
  };

  useEffect(() => {
    fetchProviderKYCApprovalList();
  }, []);

  // set coulumns when there is change in rows
  useEffect(() => {
    const columns = [
      {
        field: "providerName",
        headerName: "Name",
        width: 150,
      },
      {
        field: "mobileNumber",
        headerName: "Mobile Number",
        width: 150,
 
      },
      {
        field: "email",
        headerName: "Email",
        width: 200,
 
      },
     
      {
        field: "Address Proof",
        headerName: "Address Proof",
        width: 120,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Chip
            disabled={!params?.row?.addressDocumentStatus}
            icon={<VisibilityIcon sx={{ color: "#2c64b8" }} />}
            label="view"
            onClick={() => {
              const providerEmail = params?.row?.email;
              fetchDocmentdetails(providerEmail, "Address Proof");
              setApproveDialogOpen(true);
            }}
          />
        ),
      },
      {
        field: "isAddressProofApproved",
        headerName: "Addresss Status",
        width: 180,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Chip
            variant="filled"
            // sx={{ width: "80px" }}
            label={capitalizeFirstLetter(params?.row?.addressDocumentStatus || "Not Found")}
            color={(() => {
              switch (params?.row?.addressDocumentStatus) {
                case "APPROVED":
                  return "success";
                case "REJECTED":
                  return "error";
                case "UPLOADED":
                  return "warning";
                default:
                  return "primary";
              }
            })()}
            size="small"
          />
        ),
      },

      {
        field: "Id Proof",
        headerName: "Id Proof",
        width: 120,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Chip
            icon={<VisibilityIcon sx={{ color: "#2c64b8" }} />}
            label="view"
            disabled={!params?.row?.identityDocumentStatus}
            onClick={() => {
              const providerEmail = params?.row?.email;
              fetchDocmentdetails(providerEmail, "Id Proof");
              setApproveDialogOpen(true);
            }}
          />
        ),
      },
      {
        field: "isIdentityProofApproved",
        headerName: "Id Status",
        width: 180,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Chip
            key={params?.row?.id}
            variant="filled"
            // sx={{ width: "80px" }}
            label={capitalizeFirstLetter(params?.row?.identityDocumentStatus || "Not Found")}
            color={(() => {
              switch (params?.row?.identityDocumentStatus) {
                case "APPROVED":
                  return "success";
                case "REJECTED":
                  return "error";
                case "UPLOADED":
                  return "warning";
                default:
                  return "primary";
              }
            })()}
            size="small"
          />
        ),
      },
    ];

    setTableCols(columns);
  }, [setTableRows, tableRows]);

  // API call to fetch document proof details
  const fetchDocmentdetails = async (providerEmail, documentName) => {
    if (!providerEmail) return;
    setFetchigDocument(true);
    setDialogTitle(documentName);
    let response = [];
    if (documentName === "Address Proof") {
      response = await ProviderService.fetchAddressKycDocumentDetails(
        providerEmail
      );
    } else {
      response = await ProviderService.fetchIdentityKycDocumentDetails(
        providerEmail
      );
    }

    if (response && response?.length) {
      setDocumentData(response);
    }else{
      setDocumentData([ {
        "documentId": "",
        "documentUrl": null,
        "documentExtension": "",
        "isApproved": false
    }]);
    }
    setFetchigDocument(false);
  };

  // API call to approve document
  const hanldleAprroveDocument = async (document) => {
    setApproveDialogOpen(!isApproveDialgOpen);
    const { type, id, isApprove } = document;

    switch (type) {
      case "Address Proof":
        try {
          const res = await ProviderService.updateAddressProofDocument(
            id,
            isApprove
          );
          if (res?.data && res?.succeeded) {
            await fetchProviderKYCApprovalList();
            toast.success("Update Success !");
          }
        } catch (error) {
          toast.error("Update failed !");
        }
        break;

      case "Id Proof":
        try {
          const res = await ProviderService.updateIdentityProofDocument(
            id,
            isApprove
          );
          if (res?.data?.data && res?.data?.succeeded) {
            await fetchProviderKYCApprovalList();
            toast.success("Update Success !");
          }
        } catch (error) {
          toast.error("Update failed !");
        }
        break;
      default:
        break;
    }
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      {/**open dialog when click on view */}
      <DocumentApproveDialog
        open={isApproveDialgOpen}
        onCancel={() => setApproveDialogOpen(!isApproveDialgOpen)}
        onConfirm={hanldleAprroveDocument}
        details={documentData}
        loading={isfetchigDocument}
        title={dialogTilte}
      />
      {isfetchingData ? (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <DataGrid
          rows={tableRows}
          columns={tableCols}
          pageSize={5}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10, 20, 50, 100]}
        />
      )}
    </div>
  );
}
