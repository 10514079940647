import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetSubscriberBookings } from "../../hooks/subscriber";
import AppointmentStatus from "./AppointmentStatus";
import EmptyList from "../../components/NoResult";
import CardSkeleton from "../../components/CardSkeleton";
import Notification from "../../components/Notification"
import { useGetProviderAppointments } from "../../hooks/provider";
import ProviderAppointmentCard from "./ProviderAppointmentCard";

const options = [
  { name: "All", key: "All" },
  { name: "Pending", key: "CREATED" },
  { name: "Accepted", key: "ACCEPTED" },
  { name: "Pending Payments", key: "" },
  { name: "Finished", key: "" },
  { name: "Closed", key: "EXPIRED" },
];

const ProviderDashboard = () => {
  const {isLoading,data: appointmentData,error,} = useGetProviderAppointments();
  const [filteredData, setFilteredData] = useState([]);
 
  useEffect(() => {
    setFilteredData(appointmentData);
  }, [isLoading]);

  if(error){
    Notification(error?.message ?? "Internal server error",true);
  }


  return (
    <Box>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          mt: 2,
          color: "#0C095D",
          display: "flex",
          justifyContent: "center",
          py: 1,
          mx: 2,
          borderRadius: "20px",
        }}
      >
        Appointments
      </Typography>
      <AppointmentStatus setData={setFilteredData} data={appointmentData} options={options} />
      <Box
        sx={{
          mx: 2,
          my: 2,
          backgroundColor: "#f0f0f0",
          p: 5,
          borderRadius: "20px",
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          {isLoading ? (
            new Array(3).fill(undefined)?.map((value, idx) => (
              <Grid item xs={12} sm={4} md={4} key={idx}>
                <CardSkeleton key={idx} />
              </Grid>
            ))
          ) : filteredData && filteredData?.length ? (
            filteredData?.map((service, idx) => (
              <Grid item xs={12} sm={6} md={4} key={idx}>
                <ProviderAppointmentCard service={service} key={idx} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12} sm={12} md={12} key={1}>
              <EmptyList />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProviderDashboard;
