import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCategory } from "../../Redux/slice/common";

const Category = ({ category,height}) => {
  const selectedCategory=useSelector((state)=>state.app)?.category;
  const dispatch=useDispatch();
  return (
    <div onClick={()=>dispatch(setCurrentCategory(category))}>
      <Card
      sx={{
        maxWidth: "100%",
        borderRadius: "15px",
        ":hover": {
          boxShadow: 10,
          cursor: "pointer",
          color: "orange",
        },
        background:category.code===selectedCategory?.code ? "#BEF3F5":"#E8FFFF",
        boxShadow:category.code===selectedCategory?.code?10:"",
        color:category.code===selectedCategory?.code?"orange":"",
        border:"1px solid gray",
        p:1
      }}
      elevation={2}
    >
      <CardMedia
        component="img"
        alt="green iguana"
        height={height}
        image={category?.imageUri}
        sx={{ objectFit: "contain" }}
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="body1"
          component="div"
          sx={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}
        >
          {category?.name}
        </Typography>
      </CardContent>
    </Card>
    </div>
  );
};

export default Category;
