import { useMutation, useQuery } from "@tanstack/react-query";
import UserService from "../services/userService";
import { LocalStorageHelper, handleTokenExpire } from "../utils/helperFunctions";
import Notification from "../components/Notification";
import { LocalStorageKey } from "../utils/constant";

export const useGetSubscriberBookings = () => {
  const query = useQuery({
    queryKey: ["subscriber_bookings"],
    queryFn: UserService.getBookingList,
    retry: false,
  });

  if (!query?.data?.data?.data && query?.data?.data?.errors?.[0]?.description === "Token has expired.") {
   return handleTokenExpire(query);
  }
  const modifiedData = query?.data?.data?.data?.data?.map((d) => {
    return {
      bookingNumber: d.bookingNumber,
      status: d.bookingStatus,
      service: d.providerService?.service?.name,
      date: d.bookingDate,
      startTime: d.bookingStartTime,
      endTime: d.bookingEndTime,
      paymentMode: d?.amountPaidThrough,
      cost: d?.amountToBeCharged,
      providerContactNumber: d?.provider?.mobileNumber,
      acceptedDate: d?.bookingAcceptedDate,
    };
  });

  return { ...query, data: modifiedData };
};

export const useUpdateProfile = () => {
  const mutation = useMutation({
    mutationFn: async (userData) => {
        if(userData?.profileImage){
         const response = await UpdateProfileImage(userData.profileImage);
         if (!response.success) {
           return {
             data: {
               errors: [{ description: response.message }],
             },
           };
         }
        }
      return await UserService.updateProfile(userData);
    },
    onSuccess: (data, variables, context) => {
      const response = data;
      if (!response?.data?.data?.data && response?.data?.errors?.[0]?.description === "Token has expired.") {
        return handleTokenExpire(mutation);

       }
       if(!response?.data?.data && !response?.data?.succeeded){
        const errorMessage=response?.data?.errors?.[0]?.description ?? "Internal Server Error."
        Notification(errorMessage,true)
        return mutation
       }
      
        const { firstName, lastName, email, mobileNumber } = variables ;
        const userData = LocalStorageHelper.getData(LocalStorageKey.USER_INFO);
        const updatedUserInfo = {
          ...userData,
          user_Info: { ...userData.user_Info, firstName, lastName, email, mobileNumber},
        };

        LocalStorageHelper.setData(LocalStorageKey.USER_INFO, updatedUserInfo);

        Notification("Profile Updated successfully.");
        return mutation;
      
       
    },
    onError: (error) => {
      const errorMessage = error?.message ?? "Internal Server Error";
      Notification(errorMessage, true);
    },
  });

  return mutation;
};

export const useGetUserProfile=()=>{
  const query = useQuery({
    queryKey: [LocalStorageHelper.getLoggedInUserRole()],
    queryFn: UserService.getProfile,
    retry: false,
  });
  if (
    !query?.data?.data?.data &&
    query?.data?.data?.errors?.[0]?.description === "Token has expired."
  ) {
    return handleTokenExpire(query);
  }
  return { ...query, data: query?.data?.data?.data };
}

const UpdateProfileImage=async(profileImage)=>{
  try {
    const response = await UserService.updateProfileImage(profileImage);
    if (!response.data.succeeded) {
      const errorMessage =
        response?.data?.errors?.[0]?.description 
        ?? "Failed to upload profile photo !";
      return { success: false, message: errorMessage };
    }
    return { success: true, message: "" };
  } catch (error) {
    return { success: false, message: "Failed to upload profile photo !" };
  }
}
