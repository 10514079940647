import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Logout from "@mui/icons-material/Logout";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LockIcon from "@mui/icons-material/Lock";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupsIcon from "@mui/icons-material/Groups";
import { useNavigate } from "react-router-dom";
import { LocalStorageHelper } from "../utils/helperFunctions";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VerifiedIcon from "@mui/icons-material/Verified";
import HelpIcon from "@mui/icons-material/Help";
import DashboardIcon from '@mui/icons-material/Dashboard';

export default function AccountMenu() {
  const userData = LocalStorageHelper.getUserData();
  const currentUserRole=userData?.userRoleCode;
  const [MenuList, setMenuList] = useState([
    {
      menu: "Home",
      icon: (
        <HomeIcon
          sx={{
            color: "#F89809",
            backgroundColor: "#FFE7C3",
            borderRadius: "50%",
            p: 0.8,
            fontSize: "2rem",
            mr: 1.5,
          }}
        />
      ),
      forUserType: ["PROVIDER", "SUBSCRIBER", "GUEST","SUPERADMIN"],
      action: () => navigate("/"),
    },
    {
      menu: "View as Subscriber",
      path: "#",
      icon: (
        <AccountCircleIcon
          sx={{
            color: "#F89809",
            backgroundColor: "#FFE7C3",
            borderRadius: "50%",
            p: 0.8,
            fontSize: "2rem",
            mr: 1.5,
          }}
        />
      ),
      forUserType: ["PROVIDER"],
      action: () => {},
    },
    {
      menu: "Edit Profile",
      path: "#",
      icon: (
        <ManageAccountsIcon
          sx={{
            color: "#F89809",
            backgroundColor: "#FFE7C3",
            borderRadius: "50%",
            p: 0.8,
            fontSize: "2rem",
            mr: 1.5,
          }}
        />
      ),
      forUserType: ["PROVIDER", "SUBSCRIBER"],
      action: () => navigate('/edit/profile'),
    },
    {
      menu: "Update KYC",
      path: "#",
      icon: (
        <VerifiedIcon
          sx={{
            color: "#F89809",
            backgroundColor: "#FFE7C3",
            borderRadius: "50%",
            p: 0.8,
            fontSize: "2rem",
            mr: 1.5,
          }}
        />
      ),
      forUserType: ["PROVIDER"],
      action: () => {},
    },
    {
      menu: "My Address",
      path: "#",
      icon: (
        <LocationOnIcon
          sx={{
            color: "#F89809",
            backgroundColor: "#FFE7C3",
            borderRadius: "50%",
            p: 0.8,
            fontSize: "2rem",
            mr: 1.5,
          }}
        />
      ),
      forUserType: ["SUBSCRIBER"],
      action: () => {},
    },
    {
      menu: "Change Password",
      path: "#",
      icon: (
        <LockIcon
          sx={{
            color: "#F89809",
            backgroundColor: "#FFE7C3",
            borderRadius: "50%",
            p: 0.8,
            fontSize: "2rem",
            mr: 1.5,
          }}
        />
      ),
      forUserType: ["PROVIDER", "SUBSCRIBER"],
      action: () => {},
    },

    {
      menu: "Appointments",
      icon: (
        <GroupsIcon
          sx={{
            color: "#F89809",
            backgroundColor: "#FFE7C3",
            borderRadius: "50%",
            p: 0.5,
            fontSize: "2rem",
            mr: 1.2,
          }}
        />
      ),
      forUserType: ["PROVIDER", "SUBSCRIBER"],
      action: () => {
        switch (currentUserRole) {
          case "SUBSCRIBER":
            navigate("/subscriber/dashboard");
            break;
          case "PROVIDER":
            navigate("/provider/dashboard");
            break;

          default:
            navigate("/");
            break;
        }
      },
    },
    {
      menu: "Help",
      path: "#",
      icon: (
        <HelpIcon
          sx={{
            color: "#F89809",
            backgroundColor: "#FFE7C3",
            borderRadius: "50%",
            p: 0.5,
            fontSize: "2rem",
            mr: 1.2,
          }}
        />
      ),
      forUserType: ["PROVIDER", "SUBSCRIBER"],
      action: () => {},
    },
    {
      menu: "Delete Account",
      icon: (
        <DeleteIcon
          sx={{
            color: "#F89809",
            backgroundColor: "#FFE7C3",
            borderRadius: "50%",
            p: 0.8,
            fontSize: "2rem",
            mr: 1.5,
          }}
        />
      ),
      path: "#",
      forUserType: ["PROVIDER", "SUBSCRIBER"],
      action: () => {},
    },
    {
      menu: "Dashboard",
      icon: (
        <DashboardIcon
          sx={{
            color: "#F89809",
            backgroundColor: "#FFE7C3",
            borderRadius: "50%",
            p: 0.5,
            fontSize: "2rem",
            mr: 1.2,
          }}
        />),
        path: "#",
        forUserType: ["SUPERADMIN"],
        action: () => {
          navigate("/profile");
        },
    },
    {
      menu: "Logout",
      icon: (
        <Logout
          sx={{
            color: "#F89809",
            backgroundColor: "#FFE7C3",
            borderRadius: "50%",
            p: 0.5,
            fontSize: "2rem",
            mr: 1.2,
          }}
        />
      ),
      path: "#",
      forUserType: ["PROVIDER", "SUBSCRIBER","SUPERADMIN"],
      action: () => {
        LocalStorageHelper.clear();
        navigate("/");
        // window.location.reload();
      },
    },
  ]);

  useEffect(() => {
    const currentUserRole = LocalStorageHelper.getLoggedInUserRole();
    const filterdList = MenuList.filter((m) =>
      m.forUserType.includes(currentUserRole)
    );
    setMenuList(filterdList);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          onMouseOver={handleClick}
          size="small"
          sx={{ ml: 2, cursor: "pointer" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 45, height: 45, cursor: "pointer",border:"1px solid lightgray" }} src={userData?.profileImageUri}>
            {userData?.firstName?.split("")?.[0]?.toUpperCase() ?? "N"}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        MenuListProps={{ onMouseLeave: handleClose }}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {MenuList?.map((opt, idx) => (
          <MenuItem onClick={opt.action} key={idx}>
            <ListItemIcon key={idx}>{opt.icon}</ListItemIcon>
            {opt.menu}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
