import { LocalStorageKey, defaultLocation } from "./constant";

export const validateEmptyFields = (data) => {
  let errors = {};
  const keys = Object.keys(data);

  keys.forEach((key) => {
    if (!data[key]) {
      errors[key] = true;
    }
  });

  return errors;
};

export const LocalStorageHelper = {
  setData: (key, data) => {
    if (key && data) {
      localStorage.setItem(key, JSON.stringify(data));
    }
  },
  getData: (key) => {
    if (key) {
      return JSON.parse(localStorage.getItem(key));
    }
    return null;
  },
  removeItem: (key) => {
    if (key) {
      return localStorage.removeItem(key);
    }
  },
  clear: () => {
    localStorage.removeItem(LocalStorageKey.IS_LOGGED_IN);
    localStorage.removeItem(LocalStorageKey.TOKEN);
    localStorage.removeItem(LocalStorageKey.USER_INFO);
    return
  },
  getToken:()=>{
    const userData=JSON.parse(localStorage.getItem(LocalStorageKey.USER_INFO));
    return {Token:userData?.access_Token ?? null,userType:userData?.user_Info?.userRoleCode}
  },
  getJWTToken:()=>{
    const userData=JSON.parse(localStorage.getItem(LocalStorageKey.USER_INFO));
    return userData?.access_Token ?? null
  },
  getLoggedInUserRole:()=>{
    const userData=JSON.parse(localStorage.getItem(LocalStorageKey.USER_INFO));
    return userData?.user_Info?.userRoleCode ?? null
  },
  getUserData:()=>{
    return JSON.parse(localStorage.getItem(LocalStorageKey.USER_INFO))?.user_Info;
  }

};

//function to convert json object to formdata
export const jsonToFormData=(jsonData)=> {
  if(!jsonData){
    return
  }
  const formData = new FormData();
  for (let key in jsonData) {
    formData.append(key, jsonData[key]);
  }
  return formData;
}

//function to get users latitude and longitude
export const getLongitudeAndLatitude = () => {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          resolve({ latitude:latitude.toString(), longitude:longitude.toString() });
        },
        error => {
          reject(error);
        }
      );
    });
  } else {
    alert("Geolocation is not supported by this browser. Defaulting to random location.");
    return Promise.resolve({ latitude: defaultLocation.Latitude, longitude: defaultLocation.Longitude });
  }
};

export const formatDate = (datetimeString) => {
  const options = { day: '2-digit', month: 'long', year: 'numeric' };
  return new Date(datetimeString?.substring(0, 10)).toLocaleDateString('en-GB', options);
}

export const formatTime=(datetimeString)=>{
const date = new Date(datetimeString);

const options = { hour: '2-digit', minute: '2-digit', hour12: true };
const time12HourFormat = date.toLocaleTimeString('en-US', options);
return time12HourFormat;

}

export const handleTokenExpire=(data)=>{
  LocalStorageHelper.clear();
  // window.location.reload();
  window.location.pathname = "/";
  return data;
}

export const capitalizeFirstLetter=(str) =>{
  if (typeof str !== 'string' || !str) {
    return ''; // Return an empty string if the input is not a string or is empty
  }

  // Convert the string to lowercase and capitalize the first letter
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

