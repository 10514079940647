import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LocalStorageHelper } from "./utils/helperFunctions";
import { UserTypes } from "./utils/constant";

export const SuperAdminRoute = ({ component: Component }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const { Token, userType } = LocalStorageHelper.getToken();
    if (!Token || userType !== UserTypes.SUPERADMIN) {
      return navigate("/");
    }
  }, []);
  return <Component />;
};

export const ProviderRoute = ({ component: Component }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const { Token, userType } = LocalStorageHelper.getToken();
    if (!Token || userType !== UserTypes.PROVIDER) {
      return navigate("/");
    }
  }, []);
  return <Component />;
};

export const SubscriberRoute = ({ component: Component }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const { Token, userType } = LocalStorageHelper.getToken();
    if (!Token || userType !== UserTypes.SUBSCRIBER) {
      return navigate("/");
    }
  }, []);
  return <Component />;
};
