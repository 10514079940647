import React, { useEffect } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { FormControl, TextField } from "@mui/material";

const basedStyle = {
  // width: "100%",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  padding: "0.8rem",
  borderRadius: "4px",
  margin: " 0 1%",
  width: "3.8rem",
  // display: "none"
};

const CustomPhoneInput = ({
  setCountryCode = () => {},
  value = "",
  onChange = () => {},
  countryCode,
  name = "",
  autoFocus = false,
  disabled = false,
  type="text"
}) => {

  const handleChange = (e) => {
    onChange(e);
  };
  return (
    <FormControl
      variant="outlined"
      sx={{ display: "flex", flexDirection: "row", width: "100%", mt: 1 }}
    >
     {disabled 
     ? <TextField sx={{width:"80px",mr:1}} disabled={disabled} value={countryCode}></TextField>
     :
      <PhoneInput
      placeholder="Enter phone number"
      value={countryCode}
      onChange={(e) => setCountryCode(e)}
      defaultCountry="IN"
      international
      maxLength={15}
      numberInputProps={{}}
      disabled={disabled}
      rules={{
        required: true,
        validate: (countryCode) => isValidPhoneNumber(countryCode),
      }}
      style={basedStyle}
    />}
    
      <TextField
        fullWidth
        placeholder="Enter Mobile Number"
        value={value}
        autoFocus={autoFocus}
        name={name}
        type={type}
        label={"Phone Number."}
        disabled={disabled}
        onChange={(e) => handleChange(e)}
      ></TextField>
    </FormControl>
  );
};

export default CustomPhoneInput;
