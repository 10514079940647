import React, { useState, useEffect } from "react";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import { Box, CircularProgress, IconButton } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";

const Timer = ({retry,setOtp=()=>{}}) => {
  const [timer, setTimer] = useState(60);
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [isLoading,setLoading]=useState(false);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(timerInterval);
          setShowRetryButton(true);
          return prevTimer;
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [showRetryButton]);

  const handleRetryClick = async() => {
    setLoading(true);
    await retry()
    setTimer(60);
    setOtp('')
    setLoading(false);
    setShowRetryButton(false);
  };

  return (
    <div>
      <div>
        {timer > 0 && (
          <Box sx={{ display: "flex", gap: 1 }}>
           <WatchLaterOutlinedIcon /> 
            <div>
              {Math.floor(timer / 60)}:
              {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
            </div>
          </Box>
        )}
      </div>
      {showRetryButton && (
        <IconButton aria-label="retyr" onClick={handleRetryClick}>
         {isLoading ? <CircularProgress size={25}/> : <ReplayIcon />}
        </IconButton>
      )}
    </div>
  );
};

export default Timer;
