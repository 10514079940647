import {
  Box,
  Card,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { formatDate, formatTime } from "../../utils/helperFunctions";
import { ServiceStatus } from "../../utils/constant";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import ContactNumber from "./ContactNumber";

const SubscriberAppointmentCard = ({ service }) => {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Card
      sx={{
        maxWidth: "100%",
        p: 2,
        borderRadius: "15px",
        backgroundColor: "#F8F8F8",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        border: "2px solid #ccc",
        marginBottom: "20px",
        height: isMediumScreen ? 210 : "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          flexDirection: "column",
          gap: 1,
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="body2">
            <span
              style={{
                color: "#0C095D",
                fontWeight: "bold",
                marginRight: "5px",
              }}
            >
              {"Status :"}
            </span>
            {ServiceStatus[service?.status]}{" "}
          </Typography>
          <Typography variant="body2">
            {formatDate(service?.acceptedDate)}
          </Typography>
        </Box>
        <Typography variant="body2">
          <span
            style={{ color: "#0C095D", fontWeight: "bold", marginRight: "5px" }}
          >
            {"Booking Number :"}
          </span>
          {service?.bookingNumber}{" "}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          mb: 2,
        }}
      >
        <Typography variant="body2">
          <span
            style={{ color: "#0C095D", fontWeight: "bold", marginRight: "5px" }}
          >
            {<HomeRepairServiceIcon />}
          </span>
          {service?.service}
        </Typography>

        <ContactNumber contactNumber={service?.providerContactNumber} key={service?.bookingNumber}/>

      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Typography variant="body2">
          <span
            style={{ color: "#0C095D", fontWeight: "bold", marginRight: "5px" }}
          >
            {<CalendarMonthIcon />}
          </span>
          {formatDate(service?.date)}{" "}
        </Typography>
        <Typography variant="body2">
          <span
            style={{ color: "#0C095D", fontWeight: "bold", marginRight: "5px" }}
          >
            {<AccessTimeIcon />}
          </span>
          {`${formatTime(service?.startTime)} - ${formatTime(
            service?.endTime
          )}`}
        </Typography>
      </Box>

      {service?.paymentMode && service?.cost && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            mt: 2,
          }}
        >
          <Typography variant="body2">
            {" "}
            <span
              style={{
                color: "#0C095D",
                fontWeight: "bold",
                marginRight: "5px",
              }}
            >
              {"Mode"}
            </span>
            {service?.paymentMode}
          </Typography>
          <Typography variant="body2">
            {" "}
            <span style={{ color: "#0C095D", fontWeight: "bold" }}>
              {"Total Cost"}
            </span>{" "}
            <CurrencyRupeeIcon sx={{ fontSize: "1rem" }} /> {service?.cost}
          </Typography>
        </Box>
      )}
    </Card>
  );
};

export default SubscriberAppointmentCard;
