import axios from "axios";
import { isValidPhoneNumber } from "react-phone-number-input";
import axiosInstance from "./httpIntercepter";
import { LocalStorageHelper, jsonToFormData } from "../utils/helperFunctions";
import { UserAPIConfiguration } from "../utils/API";

const UserService = {
  Login: async (payload) => {
    try {
      const response = await axios.post(UserAPIConfiguration.Login, payload, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      });
      if (response?.data && response?.data?.succeeded) {
        return {
          data: response?.data?.data,
          error: null,
        };
      } else {
        return {
          data: null,
          error: "Login Failed !",
        };
      }
    } catch (error) {
      return {
        data: null,
        error: "Login Failed !",
      };
    }
  },

  checkEmailAvailable: async (email) => {
    
      const response = await axios.get(UserAPIConfiguration.checkEmailAvaialable,{
          params: { userEmail: email },
        }
      );
      if(!response.data?.succeeded){
        return {
          verified: false,
          error: true,
          message: response.message || "Email verification failed.",
        };
      }

      if(response.data?.succeeded && !response.data?.data ){
        return {
          verified: response.data?.data,
          error: true,
          message: `Email is already registerd.`,
        };
      

      }
      return {
        verified: response.data?.data,
        error: false,
        message: `Email ${email} is available.`,
      };
    
  },

  checkMobileNumberAvailable: async (mobileNumber) => {
   
      if (!isValidPhoneNumber(mobileNumber)) {
        return {
          verified: false,
          error: true,
          message:"Please provide valid mobile number",
        };
        
      }
      const response =  await axios.get(UserAPIConfiguration.checkMobileNumberAvailable,{
          params: { userPhone: mobileNumber },
        }
      );

      if(!response.data?.succeeded ){
        return {
          verified: false,
          error: true,
          message: response.message || "Mobile number verification failed.",
        };
      }
      if(response.data?.succeeded && !response?.data?.data){
        return {
          verified: response.data?.data,
          error: false,
          message: "Mobile number already registered.",
        };
      }
      return {
        verified: response.data?.data,
        error: false,
        message: "Mobile number verfied.",
      };
   
  },

  sendMobileVerificationOpt: async (mobileNumber, mobileCountryCode) => {
    try {
      if (!mobileCountryCode || !mobileNumber) {
        throw new Error("Invalid country code or mobile number.");
      }
      const { data, status } = await axios.post(UserAPIConfiguration.sendMobileNumberVerifyOpt,{
          mobileCountryCode,
          mobileNumber,
        }
      );

      if (status === 200 && data?.succeeded) {
        if (data?.data) {
          return {
            success: true,
            data:data.data,
            error: false,
            message: `OTP sent to ${mobileNumber}.`,
          };
        } else {
          return {
            success: false,
            data:{},
            error: false,
            message: "Failed to sent OTP.",
          };
        }
      } else {
        throw new Error("Failed to sent OTP.");
      }
    } catch (error) {
      return {
        success: false,
        data:{},
        error: true,
        message: error.message || "Internal Server Error",
      };
    }
  },

  verifyMobileNumberOpt:async(otp,mobileNumber)=>{
    try {
      if (!otp || !mobileNumber) {
        throw new Error("Invalid mobile number or otp.");
      }
      const { data, status } = await axios.post(UserAPIConfiguration.verifyMobileNumberOtp,{
          otp,
          mobileNumber,
        }
      );

      if (status === 200 && data?.succeeded) {
        if (data.succeeded) {
          return {
            success: true,
            data:data.data,
            error: false,
            message: "Mobile number verified.",
          };
        } else {
          return {
            success: false,
            data:{},
            error: false,
            message: "Failed to verify mobile number.",
          };
        }
      } else {
        if(data?.errors && data.errors.length){
          throw new Error(data.errors[0]?.description);
        }
        // console.log(data.errors)
        throw new Error("Failed to verify mobile number.");
      }
    } catch (error) {
      return {
        success: false,
        data:{},
        error: true,
        message: error.message || "Internal Server Error", 

      };
    }
  },

  registerUser:async(payload)=>{
    const formData=jsonToFormData(payload);
    const response= await axios.post(UserAPIConfiguration.register,formData, 
     );
    if(!response.data?.succeeded){
      return {
        success: false,
        error: true,
        message: response?.message || "Failed to register user",
      };
    }
   
      return {
        success: true,
        data:response?.data,
        error: false,
        message: response?.message || "Successfully registered.",
      };

    
  },

  getBookingList:async()=>{
    const token = LocalStorageHelper.getJWTToken();
    return await axios.get(UserAPIConfiguration.userBookings,{
      headers: {
        "Content-Type": "application/json",
        "Accept": "*/*",
        "Authorization":`Bearer ${token}`
      }
    });
  },

  updateProfile:async(payload)=>{
    const token = LocalStorageHelper.getJWTToken();
    return await axios.put(UserAPIConfiguration.updateProfile,payload,{
      headers:{
        "Content-Type": "application/json",
        "Accept": "*/*",
        "Authorization":`Bearer ${token}`
      }
    })
  },

  getProfile:async()=>{
    const token = LocalStorageHelper.getJWTToken();
    return await axios.get(UserAPIConfiguration.getUserProfile,{
      headers: {
        "Content-Type": "application/json",
        "Accept": "*/*",
        "Authorization":`Bearer ${token}`
      }
    });
  },

  updateProfileImage:async(payload)=>{
    const token = LocalStorageHelper.getJWTToken();
    const formData = new FormData();
    formData.append("image", payload); 
    return await axios.post(UserAPIConfiguration.updateProfileImage,formData,{
      headers:{
        // "Content-Type": "application/json",
        // "Accept": "*/*",
        "Authorization":`Bearer ${token}`
      }
    })
  },

  getEmailVerifyOtp:async()=>{
    return await axiosInstance.get(UserAPIConfiguration.getEmailVerifyOtp)
  },
  verifyEmailOtp:async(payload)=>{
    return await axiosInstance.post(UserAPIConfiguration.verifyEmailOtp,payload);
  }


}


export default UserService;
