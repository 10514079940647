import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import KycApprovalList from "../pages/super-admin/dashboard/kyc-approval-list";
import WorkAuthApproval from "../pages/super-admin/dashboard/work-auth-approval";


const AdminDashboard = () => {
  const [openTab, setOpenTab] = useState("KYC Approval");
  const handleTabChange = (event, newTab) => {
    setOpenTab(newTab);
  };
  return (
    <Container>
      <Typography
        variant="h4"
        sx={{ color: "#2c64b8", fontWeight: "bold", mt: 4, ml: 2, mb: 1.5 }}
      >
        LIST OF SERVICE PROVIDER
      </Typography>

      <Box sx={{ width: "100%", mb: 2 }}>
        <Tabs
          value={openTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          aria-label="primary tabs example"
        >
          <Tab value="KYC Approval" label="KYC Approval" />
          <Tab
            value="Work Authorisation Approval"
            label="Work Authorisation Approval"
          />
        </Tabs>
      </Box>

      {/*table*/}
      <Box>
        {openTab === "KYC Approval" ? (
          <KycApprovalList />
        ) : (
          <WorkAuthApproval />
        )}
      </Box>
    </Container>
  );
};

export default AdminDashboard;
