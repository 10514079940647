import { AppBar, Box, Button, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { setLoginPopupOpen } from "../Redux/slice/common";
import { LocalStorageHelper } from "../utils/helperFunctions";
import { Link, useLocation } from "react-router-dom";
import AccountMenu from "./Menu";

const Navbar = () => {
  const loggedInUser = LocalStorageHelper.getToken();

  const dispatch = useDispatch();
  const location = useLocation();
  const [currentPath, setCurrPath] = useState();

  useEffect(() => {
    setCurrPath(location?.pathname);
  }, [location]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className="nvbar" style={{ position: "relative" }}>
        <Toolbar>
          <Box
            component="img"
            alt="app logo"
            src={Logo}
            sx={{ objectFit: "contain", height: 80, p: 1.5 }}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              ml: "auto",
            }}
          >
            {loggedInUser?.Token ? (
              <AccountMenu />
            ) : (
              <Box
                sx={{
                  display: currentPath === "/register" ? "none" : "flex",
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  // startIcon={<VpnKeyIcon />}
                  onClick={() => dispatch(setLoginPopupOpen())}
                  sx={{ color: "blue", fontWeight: "bold" }}
                >
                  Login
                </Button>
                <Link to="/register">
                  <Button
                    variant="contained"
                    size="small"
                    // startIcon={<PersonAddIcon />}
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    Join Us
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
