import { useQuery } from "@tanstack/react-query";
import ProviderService from "../services/provider-service";
import { LocalStorageHelper } from "../utils/helperFunctions";

export const useGetProviderAppointments = () => {
  const query = useQuery({
    queryKey: ["provider_appointments"],
    queryFn: ProviderService.getProviderAppointments,
    retry: false,
  });
  
  if (
    !query?.data?.data?.data &&
    query?.data?.data?.errors?.[0]?.description === "Token has expired."
  ) {
    LocalStorageHelper.clear();
    window.location.reload();
    window.location.pathname = "/";
    return query;
  }
  const modifiedData = query?.data?.data?.data?.data?.map((d) => {
    return {
      bookingNumber: d.bookingNumber,
      status: d.bookingStatus,
      service: d.providerService?.service?.name,
      date: d.bookingDate,
      startTime: d.bookingStartTime,
      endTime: d.bookingEndTime,
      paymentMode: d?.amountPaidThrough,
      cost: d?.amountToBeCharged,
      subscriberContactNumber: d?.subscriber?.mobileNumber,
      acceptedDate: d?.bookingAcceptedDate,
      subscriberAddress: {
        name: d?.subscriberAddress?.contactName,
        address: `${d?.subscriberAddress?.addressLine1}, ${d?.subscriberAddress?.landmark}, ${d?.subscriberAddress?.cityCode}, ${d?.subscriberAddress?.stateCode}, ${d?.subscriberAddress?.countryCode} , ${d?.subscriberAddress?.areaCode}`,
      },
    };
  });

  return { ...query, data: modifiedData };
};
