import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { setLoginPopupOpen } from "../Redux/slice/common";
import Logo from "../assets/images/logo.png";
import { Box, TextField, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import LoadingButton from "./LoadingBtn";
import UserService from "../services/userService";
import {
  LocalStorageHelper,
  validateEmptyFields,
} from "../utils/helperFunctions";
import {
  LocalStorageKey,
  UserTypes,
  phoneNumberRegex,
} from "../utils/constant";
import CustomPhoneInput from "./PhoneInput";
import Notification from "../components/Notification";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const Login = () => {
  const isOpen = useSelector((state) => state?.app?.isLoginPopupOpen);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    dispatch(setLoginPopupOpen());
  };
  const [isLoading, setLoading] = useState(false);
  const [isPasswordVisible,setPasswordVisible]=useState(false)
  const [credentials, setCredentials] = useState({
    userName: "",
    password: "",
  });
  const [isLoginWithPhoneNumber, setLoginWithPhoneNumber] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [errors, setError] = useState("");

  const inputChangeHandler = (e) => {
    const key = e?.target?.name;
    const value = e?.target?.value;
    if (key === "userName" &&  phoneNumberRegex.test(value)) {
      (value.length<=10) ?setLoginWithPhoneNumber(true):setLoginWithPhoneNumber(false);
    } else if(key==='userName' && !phoneNumberRegex.test(value)){
      setLoginWithPhoneNumber(false);
    }
    setCredentials((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleLogin = async () => {
    let payload = {
      ...credentials,
      userName: isLoginWithPhoneNumber
               ? countryCode ? countryCode + credentials?.userName: "+91" + credentials?.userName
              : credentials?.userName,
    };
  
    const errors = validateEmptyFields(payload);
    setError(null);
    if (Object.keys(errors).length > 0) {
      setError("Please enter valid credentials.");
      return;
    }
    setLoading(true);
    const response = await UserService.Login(payload);
    if (response?.error) {
      Notification(response.error,true)
      setLoading(false);
      return;
    }
    setLoading(false);
    LocalStorageHelper.setData(LocalStorageKey.USER_INFO, response?.data);
    Notification("Login Success.")
    //close login popup
    handleClose();
    //navigate to landing page according to userType
    navigateAfterLogin();
  };
//this function to handle navigate after successfull login according to user type
  const navigateAfterLogin = () => {
    const userData = LocalStorageHelper.getUserData();
    switch (userData?.userRoleCode) {
      case UserTypes.SUPERADMIN:
        navigate("/profile");
        break;
      case UserTypes.PROVIDER:
        userData?.emailConfirmed ?
        navigate("/provider/dashboard"):
        navigate("/provider");
        break;
      case UserTypes.SUBSCRIBER:
          navigate("/subscriber/dashboard");
          break;

      default:
        navigate("/");
        break;
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="login"
      maxWidth={"sm"}
      fullWidth={true}
    >
      <DialogTitle id="login">
        <Box sx={{ display: "flex", justifyContent: "end",}}>
          <CancelIcon
            sx={{ cursor: "pointer" }}
            onClick={(e) =>{
              if(isLoading){
                return
              }
               handleClose();
              }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <img alt="app logo" src={Logo} height={50} width={120} sx={{ objectFit:"contain"}} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ width: "100%" }}>
        {isLoginWithPhoneNumber ? (
          <CustomPhoneInput
            setCountryCode={setCountryCode}
            value={credentials.userName}
            onChange={inputChangeHandler}
            countryCode={countryCode}
            name="userName"
            disabled={isLoading}
            autoFocus={true}
          />
        ) : (
          <TextField
            sx={{ width: "100%", mt: 1 }}
            label={"Email / Phone No."}
            name="userName"
            value={credentials?.userName}
            autoFocus={true}
            disabled={isLoading}
            onChange={(e) => inputChangeHandler(e)}
          ></TextField>
        )}

        <TextField
          sx={{ width: "100%", mt: 2 }}
          label={"Password"}
          type={isPasswordVisible?"text":"password"}
          name="password"
          onChange={(e) => inputChangeHandler(e)}
          disabled={isLoading}
          InputProps={{
            endAdornment: (
              <>
                {isPasswordVisible ? (
                  <VisibilityOffIcon
                    onClick={()=>setPasswordVisible(!isPasswordVisible)}
                    sx={{ cursor: "pointer",color:"orange"  }}
                  />
                ) : (
                  <VisibilityIcon
                  onClick={()=>setPasswordVisible(!isPasswordVisible)}
                    sx={{ cursor: "pointer",color:"orange"  }}
                  />
                )}
              </>
            )
          }}
        ></TextField>
        <Box sx={{ mt: 1 }}></Box>
        <Box sx={{ display: "flex", justifyContent: "space-evenly", py: 1 }}>
          <ErrorMessage message={errors} />
          <Link to={"#"}>
            <Typography
              fontSize={"0.8rem"}
              sx={{ color: "blue", wordBreak: "keep-all" }}
            >
              ForgotPassword?
            </Typography>
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <LoadingButton style={{ width: "50%" }} />
          ) : (
            <Button
              autoFocus
              variant="contained"
              sx={{ width: "50%" }}
              onClick={handleLogin}
              disabled={
                isLoading || !credentials?.userName || !credentials?.password
              }
            >
              Sign In
            </Button>
          )}
          <Typography fontSize={"0.8rem"} sx={{ color: "blue", my: 1 }}>
            Continue as Guest
          </Typography>
            <Button sx={{color:"blue",textTransform:"none"}} disabled={isLoading}  onClick={()=>{
            dispatch(setLoginPopupOpen());
            navigate("/register")
          }}>Join us</Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Login;

const ErrorMessage = ({ message }) => {
  return (
    <Typography sx={{ fontSize: "0.8rem", color: "red", width: "100%" }}>
      {message}
    </Typography>
  );
};
