import React from "react";
import Container from "@mui/material/Container";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer/Footer";
import ERROR_404_IMG from "../assets/images/no page.png";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Page404() {
  const navigate = useNavigate();

  return (
    <Box>
      <Navbar />
      <Container sx={{ display: "grid", placeItems: "center", py: 5, gap: 1 }}>
        <Typography variant="h3" fontWeight={"bold"}>
          Sorry, Page Not Found!
        </Typography>
        <Typography>
          Sorry, we couldn’t find the page you’re looking for.
        </Typography>
        <Typography>
          Perhaps you’ve mistyped the URL? Be sure to check your spelling.
        </Typography>
        <img
          src={ERROR_404_IMG}
          style={{ height: 300, width: "auto", objectFit: "contain" }}
        ></img>
        <Button variant="contained" onClick={() => navigate(-1)}>
          Go back
        </Button>
      </Container>
      <Footer />
    </Box>
  );
}
export default Page404;
