import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingButton = ({ style }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      disabled={true}
      sx={{ ...style, textTransform: "none" }}
      startIcon={<CircularProgress size={20} color="inherit" />}
    >
      Loading...
    </Button>
  );
};

export default LoadingButton;
