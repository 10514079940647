import React from "react";
import {Grid, TextField} from "@mui/material";
import Button from "@mui/material/Button"
import stylee from "./stylee.css";
import Container from "@mui/material/Container";

class Footer extends React.Component {   
     render() {
        return(
            <div className="footer">
                <Container maxWidth="xl">
                    <Grid container spacing={2}>          
                        <Grid item xs={12} md={6} lg={6}> 
                        <div className="footer-widget">
                            <h3 className="footer-title">Contact us</h3>                          
                                                                <div className="featured-icon-box left-icon icon-align-top">
                                    <div className="featured-icon"> 
                                        <div className="ttm-icon ttm-icon_element-color-white ttm-icon_element-size-md">
                                        <span><i className="fa-solid fa-location-dot fa-2xl"></i></span>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-desc">
                                            <p><strong>India:</strong> Suite F-190, Sector 3, Cidco Colony, Thane Belapur Road,<br/> Airoli, Navi Mumbai - 400708</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="featured-icon-box left-icon icon-align-top">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-color-white ttm-icon_element-size-md">
                                        <span><i className="fa-solid fa-envelope fa-2xl"></i></span>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-desc">
                                            <p><a href="mailto:contactindia@stepstodigitech.com" className="linkto">contactindia@stepstodigitech.com</a></p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </Grid>                    
                    <Grid item xs={12} md={3}>
                        <div className="footer-widget footer-menu">
                        <h3 className="footer-title">Quick Links</h3>
                        <ul>
                            <li><a href="common/footer#">Register Your Business</a></li>
                            <li><a href="common/footer#">Help Desk</a></li>
                            <li><a href="common/footer#">Verify Your Profile</a></li>
                            </ul>                            
                        </div>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <div className="footer-widget footer-menu">
                        <h3 className="footer-title">Info</h3>
                        <ul>
                            <li><a href="https://stepstodigitech.com/about-us/" target="blank">About Us</a></li>
                            <li><a href="https://servicesonclicks.com/privacy-policy/" target="blank">Privacy Policy</a></li>
                            <li><a href="https://servicesonclicks.com/terms/" target="blank">Terms & Conditions</a></li>
                            <li><a href="https://servicesonclicks.com/refund-policy/" target="blank">Refund Policy</a></li>
                            </ul>                            
                        </div>
                    </Grid>
                                           </Grid>
                </Container>
                <div className="cprght">
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} lg={6}>
                        <p className="cpytxt"><span>Copyright © <script type="text/javascript">document.write(new Date().getFullYear());</script>2023&nbsp;<strong>STDT IT Services</strong>. All Rights Reserved.</span></p>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <div className="icodown">
                            <a href="#"><img src="../images/ggl.png"/></a>
                            <a href="#"><img src="../images/apl.png"/></a>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                       <p className="soc-icon">
                           <span><a href="https://www.linkedin.com/company/stdt-it-services/" target="blank"><i className="fa-brands fa-linkedin fa-xl"></i></a></span>
                           
                       </p>
                    </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default Footer;