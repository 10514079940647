import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer/Footer";
import { Box } from "@mui/material";
import Login from "../components/Login";
import { Toaster } from "react-hot-toast";
const App_Layout = () => {
  return (
    <>
      <Navbar />
      <Login />
      <Box sx={{ mb: 5 }}>
        <Outlet />
      </Box>
      <Toaster />
      <Footer />
    </>
  );
};

export default App_Layout;
