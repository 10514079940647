export const AUTH_BASE_URI="https://servicesonclick-authentication.azurewebsites.net/api/";
export const ADMIN_BASE_URI="https://servicesonclick-admin.azurewebsites.net";
export const ACCOUNT_BASE_URI="https://servicesonclick-account.azurewebsites.net/api/";
export const META_DATA_BASE_URI="https://servicesonclick-metadata.azurewebsites.net/api/";
export const COMMON_BASE_URI="https://servicesonclick-common.azurewebsites.net/api/"

export const UserAPIConfiguration={
    Login:AUTH_BASE_URI+"login-token",
    checkEmailAvaialable:ACCOUNT_BASE_URI+"check-email",
    checkMobileNumberAvailable:ACCOUNT_BASE_URI+"check-phone",
    sendMobileNumberVerifyOpt:ACCOUNT_BASE_URI+"/register/send-otp",
    verifyMobileNumberOtp:ACCOUNT_BASE_URI+"/register/verify-otp",
    register:ACCOUNT_BASE_URI+"register",
    userBookings:COMMON_BASE_URI+"booking/list/subscriber",
    updateProfile:ACCOUNT_BASE_URI+"profile/update",
    getUserProfile:ACCOUNT_BASE_URI+"get-user",
    updateProfileImage:ACCOUNT_BASE_URI+"profile-photo/upload",
    getEmailVerifyOtp:ACCOUNT_BASE_URI+'verification/email/get-otp',
    verifyEmailOtp:ACCOUNT_BASE_URI+'verification/email/verify-otp'
};

export const ServiceApiConfiguration={
    allCategories:META_DATA_BASE_URI+"category/list/all",
    getServicesByCategory:META_DATA_BASE_URI+"sub-category/list/all?categoryCode="
}

export const ProviderAPIConfiguration={
    getAllAppointments:COMMON_BASE_URI+"booking/list/provider",
}