import logo from "./logo.svg";
import "./App.css";

import {RouterProvider,createBrowserRouter} from "react-router-dom";
import CustomerRegistration from "./pages/customer-registration";
import Privacypolicy from "./pages/privacy-policy";
import Terms from "./pages/terms";
import Refundpolicy from "./pages/refund-policy";
import Providers from "./pages/providers";
import Profile from "./pages/profile";
import SubCategory from "./pages/sub-category";
import AddressVerification from "./pages/addressverification";
import ProviderRegistration from "./pages/provider-registration";
import Aadharverify from "./pages/aadharverify";
import Panverify from "./pages/panverify";
import App_Layout from "./Layouts/App_Layout";
import Home from "./pages/home/Home";
import { ProviderRoute, SuperAdminRoute, SubscriberRoute } from "./ProtectedRoutes";
import Provider from "./pages/Provider/Provider";
import Register from "./pages/Register/Register";
import AllServices from "./pages/All Services/AllServices";
// import Subscriber from "./pages/Subscriber/Subscriber"
import SubscriberDashboard from "./pages/Dashboard/SubscriberDashboard";
import ProviderDashboard from "./pages/Dashboard/ProviderDashboard";
import Page404 from "./pages/Page404";
import EditProfile from "./pages/Edit profile/EditProfile";
import EmailVerify from "./pages/Email-verify/EmailVerify";
import AdminDashboard from "./Layouts/admin-dashboard";

const router = createBrowserRouter([
  {
    element: <App_Layout />,
    errorElement: <Page404 />,
    children: [
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/register",
        element: <CustomerRegistration />,
      },
      {
        path: "/profile",
        element: <SuperAdminRoute component={AdminDashboard} />,
      },
      // {
      //   path: "/profile/old",
      //   element: <SuperAdminRoute component={Profile} />,
      // },
      {
        path: "/edit/profile",
        element: <EditProfile/>,
      },
      {
        path: "/provider",
        element: <ProviderRoute component={Provider} />,
      },
      // {
      //   path: "/subscriber",
      //   element: <SubscriberRoute component={Subscriber} />,
      // },
      {
        path: "/subscriber/dashboard",
        element: <SubscriberRoute component={SubscriberDashboard} />,
      },
      {
        path: "/provider/dashboard",
        element: <ProviderRoute component={ProviderDashboard} />,
      },
      // {
      //   path: "/services/all",
      //   element:  <AllServices/> ,
      // },
      {
        path: "/privacy-policy",
        element: <Privacypolicy />,
      },
      {
        path: "/terms",
        element: <Terms />,
      },
      {
        path: "/refund-policy",
        element: <Refundpolicy />,
      },
      {
        path: "/category/:categoryCode",
        element: <SubCategory />,
      },
      {
        path: "/providers/:subCategoryCode",
        element: <Providers />,
      },
      {
        path: "/providers/:subCategoryCode",
        element: <Providers />,
      },
      {
        path: "/email-verify/:email",
        element: <EmailVerify />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

// function App() {
//   return (
//     <div className="App">
//         <BrowserRouter>
//             <Routes>
//                 <Route path="/" element={<Home/>} />
//                 <Route path="login" element={<Login />} />
//                 <Route path="logout" element={<Logout />} />
//                 <Route path="register" element={<CustomerRegistration />}/>
//                 <Route path="profile" element={<Profile />}/>
//                 <Route path="privacy-policy" element={<Privacypolicy />}/>
//                 <Route path="terms" element={<Terms />}/>
//                 <Route path="refund-policy" element={<Refundpolicy />}/>
//                 <Route path="category/:categoryCode" element={<SubCategory />}/>
//                 <Route path="providers/:subCategoryCode" element={<Providers />}/>
//                 <Route path="provider/registration" element={<ProviderRegistration />}/>
//                 <Route path="addressverification" element={<AddressVerification />}/>
//                 <Route path="aadharverify" element={<Aadharverify />}/>
//                 <Route path="panverify" element={<Panverify />}/>
//                 <Route path="*" element={<Nopage />} />
//             </Routes>
//         </BrowserRouter>
//     </div>
//   );
// }

export default App;


