import axios from "axios"
import { ServiceApiConfiguration } from "../utils/API"

const Services={
    allCategories:async()=>{
        return await axios.get(ServiceApiConfiguration.allCategories);
    },
     getServicesByCategory:async(cateGoryCode)=>{
        return await axios.get(`${ServiceApiConfiguration.getServicesByCategory+cateGoryCode}`)
     }
}

export default Services;