import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import CategoryService from "../../services/category-service";
import Container from "@mui/material/Container";
import {Card, CardContent, Grid, Link} from "@mui/material";
import Rating from "@mui/material/Rating";
import CardMedia from "@mui/material/CardMedia";
import Footer from "../common/footer";

function SubCategory() {
    const params = useParams();
    const [subCategories, setSubCategories] = useState([])
    const fetchSubCategories = () => {
        CategoryService.fetchSubCategoryList(params?.categoryCode)
            .then(response => {
                console.log(response.data.data)
                setSubCategories(response?.data?.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    useEffect(() => {
        fetchSubCategories()
    }, []);

    return (
        <div>
            <div>
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <div style={{fontSize:'40px'}}>
                                <span><strong>Services in Police</strong></span>
                            </div>
                            <div>
                                <Rating name="read-only" value={4.3} readOnly />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div>

                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <Container>
                    <div>
                        <h1>Best Offers</h1>
                    </div>
                    <div>
                        <p>Expert Provisions | Top Professionals  </p>
                    </div>
                    <Grid container spacing={2}>
                        {subCategories.map(subCategory=>{
                            return(
                                <Grid item xs={4}>
                                    <Link href={'/providers/'+subCategory.code}>
                                        <Card sx={{cursor:'pointer'}}>
                                            <CardMedia
                                                sx={{ height: 140 }}
                                                image={subCategory?.imageUri}
                                                title="green iguana"
                                            />
                                        </Card>
                                    </Link>
                                    <div>
                                        <span>{subCategory?.name}</span>
                                    </div>
                                </Grid>
                            )
                        })}

                    </Grid>
                </Container>

                <Container>
                    <div style={{marginTop:'5%'}}>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={5}>
                                <Card>
                                    <CardContent sx={{textAlign:'left',
                                        backgroundColor:'#53524d',
                                        color:'white'
                                    }}>
                                        <div>
                                            <h2 style={{color:'white'}}>Best in Class Services</h2>
                                        </div>
                                        <div>
                                            <p>Courses for all levels cover technical skills, creative techniques, business strategies, and more. We have collected all of the necessary effective study.</p>
                                            <p>View More...</p>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid item xs={5}>
                                <Card>
                                    <CardContent sx={{textAlign:'left',
                                        backgroundColor:'#009da6',
                                        color:'white'
                                    }}>
                                        <div>
                                            <h2 style={{color:'white'}}>Top Rated Providers</h2>
                                        </div>
                                        <div>
                                            <p>Courses for all levels cover technical skills, creative techniques, business strategies, and more. We have collected all of the necessary effective study.</p>
                                            <p>View More...</p>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>

                        </Grid>
                    </div>
                </Container>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default SubCategory;