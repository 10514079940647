import {
  Avatar,
  Box,
  Button,
  Container,
  Skeleton,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useGetUserProfile, useUpdateProfile } from "../../hooks/subscriber";
import LoadingButton from "../../components/LoadingBtn";
import CardSkeleton from "../../components/CardSkeleton";
import Notification from "../../components/Notification";

const EditProfile = () => {
  const { isLoading, error, data: profileData } = useGetUserProfile();
  const { isPending, mutateAsync } = useUpdateProfile();
  const [userData, setUserData] = useState({});
  
  useEffect(() => {
    setUserData({
      firstName: profileData?.firstName,
      lastName: profileData?.lastName,
      email: profileData?.email,
      mobileNumber: profileData?.mobileNumber,
      profileImage: null,
    });
    setPreviewProfileImage(profileData?.profileImageUri);
  }, [profileData,isLoading]);

  const [previewProfileImage, setPreviewProfileImage] = useState(null);
  const profileImageRef = useRef(null);

  const inputChangeHandler = (e) => {
    const key = e?.target?.name;
    let value = e?.target?.value;
    if (key === "profileImage") {
      value = e.target.files[0];
      if (e.target.files.length > 0) {
        let previewSrc = URL.createObjectURL(e.target.files[0]);
        setPreviewProfileImage(previewSrc);
      }
    }

    setUserData((userData) => ({ ...userData, [key]: value }));
  };

  if (error) {
    const errorMessage = error?.message ?? "Failed to get user data.";
    Notification(errorMessage, true);
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        boxShadow: 2,
        border: "1px solid navy",
        mt: 5,
        mb: 2,
        py: 2,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <div
          style={{
            position: "relative",
          }}
        >
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            ref={profileImageRef}
            name="profileImage"
            onChange={(e) => inputChangeHandler(e)}
          ></input>

          {( isLoading || isPending) ? (
            <Skeleton variant="circular" width={80} height={80} />
          ) : (
            <Avatar
              src={previewProfileImage}
              sx={{
                width: 80,
                height: 80,
                objectFit: "contain",
                boxShadow: 5,
              }}
            />
          )}

          <div onClick={() => profileImageRef.current.click()}>
            <PhotoCameraIcon
              sx={{
                position: "absolute",
                top: 58,
                right: 0,
                color: "orange",
                fontSize: "2rem",
                cursor: "pointer",
                zIndex: 1,
                display:( isPending || isLoading) ? "none" : "",
              }}
            />
          </div>
        </div>
      </Box>
      {( isLoading || isPending) ? (
        <CardSkeleton height={300} />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
        >
          <TextField
            name="firstName"
            value={userData.firstName}
            fullWidth
            label={"First Name"}
            onChange={(e) => inputChangeHandler(e)}
          ></TextField>
          <TextField
            name="lastName"
            fullWidth
            value={userData.lastName}
            label={"Last Name"}
            onChange={(e) => inputChangeHandler(e)}
          ></TextField>
          <TextField
            name="mobileNumber"
            fullWidth
            value={userData.mobileNumber}
            label={"Phone"}
            disabled
            onChange={(e) => inputChangeHandler(e)}
          ></TextField>
          <TextField
            name="email"
            fullWidth
            value={userData.email}
            label={"Email"}
            disabled
            onChange={(e) => inputChangeHandler(e)}
          ></TextField>
         
        </Box>
      )}
      <Box sx={{display:"flex",justifyContent:"center",mt:2}}>
      {isPending ? (
            <LoadingButton style={{ width: "40%" }} />
          ) : (
            <Button
              variant="contained"
              sx={{
                backgroundColor: "orange",
                width: "40%",
                ":hover": {
                  backgroundColor: "orange",
                },
              }}
              disabled={isPending || isLoading}
              onClick={() => mutateAsync(userData)}
            >
              Submit
            </Button>
          )}
      </Box>
    </Container>
  );
};

export default EditProfile;
