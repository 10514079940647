import React from 'react'
import toast from 'react-hot-toast'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const Notification = (message,isError=false) => {
    
  return (
    <>
    {toast(message,{
        duration:2000,
        icon: isError ? <PriorityHighIcon/> : <CheckCircleOutlineIcon/>,
        style:{
          backgroundColor:isError ?"#FB4747":"#50A545",
          color:"white"
        }
      })}
    </>
  )
}

export default Notification