export const defaultLocation={
    Latitude:"18.970426",
    Longitude:"-6.00726"
}

export const LocalStorageKey={
    USER_INFO:"services_onclicks_userInfo",
    TOKEN:"access_token",
    IS_LOGGED_IN:"isloggedIn"
}
export const phoneNumberRegex= /^(?!0)[0-9]+$/;
export const emailRegex=/[a-z0-9]+@[a-z]+\.[a-z]{3}/

export const UserTypes={
    SUPERADMIN:"SUPERADMIN",
    PROVIDER:"PROVIDER",
    SUBSCRIBER:"SUBSCRIBER"
}

export const ServiceStatus={
    "CREATED":"Pending",
    "ACCEPTED":"Accepted",
    "EXPIRED":"Closed"
}

