import axios from "axios";

const CategoryService = {
    fetchCategoryList: function (){
        return axios.get('https://servicesonclick-metadata.azurewebsites.net/api/metadata/category/list',{
        })
    },
    fetchSubCategoryList: function (categoryCode) {
        return axios.get('https://servicesonclick-metadata.azurewebsites.net/api/metadata/sub-category/list?categoryCode='+categoryCode,{
        })
    }
}

export default CategoryService;