import { Box, Typography, colors } from "@mui/material";
import React from "react";
import bgImage from "../assets/images/hero.jpg";
import { Translate } from "@mui/icons-material";

const HeroSection = () => {
  return (
    <Box sx={{ height: "425px",  }}>
      <img
        src={bgImage}
        height={"100%"}
        width={"100%"}
        style={{ objectFit: "contain" }}
      ></img>
      <div style={{ ...Styles.filter, height: "425px" }}>
        <div style={{ ...Styles.textContainer }}>
          <div style={{ ...Styles.textContent }}>
            <h3 style={{ ...Styles.text }}>
              One Place for your all services in need
            </h3>
            <h1 style={{ ...Styles.text }}>
              <span style={{ ...Styles.span }}>HELP!</span>, Just few clicks
              away
            </h1>
            <input style={{ ...Styles.input }} type="search"></input>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default HeroSection;

/**styles */

const Styles = {
  filter: {
    position: "absolute",
    top: 78,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 2,
    height: "100%",
    background: "linear-gradient(178deg, rgb(0 0 0 / 58%), rgb(0 0 0 / 89%))",
  },

  textContainer: { position: "relative", width: "100%", height: "100%" },
  textContent: {
    position: "absolute",
    zIndex: 999,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  text: {
    color: "white",
    fontSize: "1.8rem",
    fontWeight: "bold",
  },
  span: {
    color: "orange",
  },
  input: {
    width: "100%",
    padding: "10px",
    outlineStyle: "none",
    borderRadius: "15px",
    fontWeight:"bold",
    color:"navy"
  },
};
