import React from "react";
import Footer from "../common/footer";
import {Card, CardContent, FormControl, Grid, InputAdornment, InputLabel, Select, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import {DesktopDatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import Button from "@mui/material/Button";
import CountUp from "react-countup";
import MenuItem from "@mui/material/MenuItem";

class ProviderRegistration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showPassword : false,
            registerFormVisible : true,
            otpFormVisible:false,
            verificationFormVisible:false,
            registrationSuccessfull:false,
        };
    }

    registerProvider = (e) => {
        e.preventDefault()
        let formData = new FormData(e.target).entries()
        for (let [key, value] of formData) {
            console.log(key,value)
        }
        this.setState({
            registerFormVisible : false,
            otpFormVisible : true
        })
    }

    verifyOtp = (e) => {
        this.setState({
            registrationSuccessfull : true,
            otpFormVisible : false
        })
    }

    validateFormValues(){

    }
    render() {
        return (
            <div>
                <div style={{
                    backgroundImage:'url("https://img.freepik.com/premium-photo/two-workers-wearing-masks-packaging-orders-contactless-food-delivery-service_236854-26708.jpg?w=900")',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'cover',
                    paddingTop:'3%',
                    paddingBottom:'2%'
                }}>
                    <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        style={{minHeight: '110vh'}}
                    >
                        <Grid item xs={5}>
                            <Card sx={{maxWidth:500}}>
                                <CardContent>
                                    {this.state.registerFormVisible && (
                                        <div>
                                            <form onSubmit={this.registerProvider}>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    justifyContent="center"
                                                >
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth margin="dense">
                                                            <TextField
                                                                id="input-with-icon-textfield"
                                                                label="First Name"
                                                                name="first_name"
                                                                required
                                                                variant="outlined"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" variant="outlined">
                                                                    <span>
                                                                        <i className="fa-regular fa-user"></i>
                                                                    </span>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth margin="dense">
                                                            <TextField
                                                                id="input-with-icon-textfield"
                                                                label="Last Name"
                                                                name="last_name"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" variant="outlined">
                                                                    <span>
                                                                        <i className="fa-regular fa-user"></i>
                                                                    </span>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth margin="dense">
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DesktopDatePicker
                                                                    name="dob"
                                                                    label="Date Of Birth"
                                                                    inputFormat="MM/DD/YYYY"
                                                                    InputAdornmentProps={{ position: 'start' }}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth margin="dense">
                                                            <TextField
                                                                id="input-with-icon-textfield"
                                                                label="Email"
                                                                name="email"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" variant="outlined">
                                                                    <span>
                                                                       <i className="fa-regular fa-envelope"></i>
                                                                    </span>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <FormControl fullWidth margin="dense">
                                                            <TextField
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Country"
                                                                select
                                                            >
                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                            </TextField>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <FormControl fullWidth margin="dense">
                                                            <TextField
                                                                id="input-with-icon-textfield"
                                                                label="Mobile Number"
                                                                name="mobile"
                                                                variant="outlined"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" variant="outlined">
                                                                        <span>
                                                                           <i className="fa-solid fa-phone"></i>
                                                                        </span>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth margin="dense">
                                                            <TextField
                                                                id="input-with-icon-textfield"
                                                                name="password"
                                                                label="Password"
                                                                variant="outlined"
                                                                type={this.state.showPassword ? "text" : "password"}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" variant="outlined">
                                                                    <span>
                                                                       <i className="fa-solid fa-lock"></i>
                                                                    </span>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl fullWidth margin="dense">
                                                            <TextField
                                                                id="input-with-icon-textfield"
                                                                name="password"
                                                                label="Confirm Password"
                                                                variant="outlined"
                                                                type={this.state.showPassword ? "text" : "password"}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start" variant="outlined">
                                                                    <span>
                                                                       <i className="fa-solid fa-lock"></i>
                                                                    </span>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Button type="submit" variant="contained">Register</Button>
                                                </Grid>
                                            </form>
                                            <div style={{fontSize:'13px'}}>
                                                <p>By Signing Up, you agree to our <a href="">Terms of Use</a> & <a href="">Privacy Policy</a></p>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.otpFormVisible && (
                                        <div>
                                            <FormControl required fullWidth onSubmit={this.registerProvider}>
                                                <Box
                                                    component="form"
                                                    sx={{
                                                        '& .MuiTextField-root': { m: 1, width: '90%' },
                                                    }}
                                                    noValidate
                                                    autoComplete="off"
                                                >
                                                    <TextField
                                                        id="input-with-icon-textfield"
                                                        name="first_name"
                                                        required
                                                        variant="outlined"
                                                    />
                                                    <p>Please Enter the OTP received on your mobile phone</p>
                                                    <Button type="button" onClick={this.verifyOtp} variant="contained">Verify</Button>
                                                    <p>Did not receive an OTP?<Button>Resend</Button></p>
                                                    <p style={{fontSize:'13px'}}>Note: If you do not receive an OTP after multiple tries, Please send us an email to <span style={{color:'blue'}}>support@steps2digitech.com</span> from your registered email address.</p>
                                                </Box>
                                            </FormControl>
                                        </div>
                                    )}
                                    {this.state.registrationSuccessfull && (
                                        <div>
                                            <div>
                                                <span><i className="fa-regular fa-circle-check fa-2xl"></i></span>
                                            </div>
                                            <div>
                                                <h2>Congratulations!</h2>
                                                <p>Your Registration is Successful!</p>
                                                <p>Verify your profile and start earning</p>
                                                <Button variant="contained">Proceed to verification</Button>
                                            </div>
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
                <div style={{backgroundColor:'#f0f2f5',paddingBottom:'8%',paddingTop:'2%'}}>
                    <div>
                        <h1>Join ServicesOnClick to change how you work</h1>
                        <p>We are a service provider market place that empower professionals like you to grow</p>
                    </div>

                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                               <div>
                                   <span style={{fontSize:'40px',display:'block',color:'#1976d2'}}>
                                       <strong>
                                           <CountUp end={999} duration={1}  separator= "," suffix="+"/>
                                       </strong>
                                   </span>
                                   <span>Partners already on board</span>
                               </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                   <span style={{fontSize:'40px',display:'block',color:'#1976d2'}}>
                                       <strong>
                                           <CountUp end={25000} duration={1}  separator= "," suffix="+"/>
                                       </strong>
                                   </span>
                                    <span>Happy Customers</span>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <div>
                                   <span style={{fontSize:'40px',display:'block',color:'#1976d2'}}>
                                       <strong>
                                           <CountUp end={100000} duration={1}  separator= "," suffix="+"/>
                                       </strong>
                                   </span>
                                    <span>Services delivered each month</span>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default ProviderRegistration;