import React, {useEffect, useState} from "react";
import NavBar from "./common/navbar";
import Footer from "./common/footer";
import Container from "@mui/material/Container";
import { useNavigate } from 'react-router-dom';
import {Alert, Card, CardContent, CardActions, FormControl, Grid, InputLabel, Snackbar, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function Addressverification(props){ 
    return (        
         <div className="addresscontainer">
                <NavBar/>               
                <Container> 
                <Grid> 
                <h1> Address Verification </h1>
                
                <Grid item sm={6} md={4} lg={3}>  
                <img src="https://picsum.photos/seed/picsum/400/200"/>   
                </Grid> 
                <Grid item sm={6} md={6} lg={12}>            
                    <Button size="small" color="primary"> <h2 className="btnh">Approve</h2></Button>
                    <Button size="medium" color="primary"> <h2 className="btnh">Reject</h2></Button>                      
                    </Grid>
                    </Grid>
                        </Container>
                <Footer/>
            </div>
    )
    }
export default Addressverification;