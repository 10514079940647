import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";


const AppointmentStatus = ({ setData, data,options }) => {
  const [currentTab, setCurrentTab] = useState("All");
  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, []);

  const handleTabChange = (tab) => {
    setCurrentTab(tab.name);
    if (tab.name === "All") {
      setData(data);
      return;
    }
    const filter = data?.filter((d) => d.status === tab.key);
    setData(filter);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ display: "flex", gap: 1,overflowX:"auto" }}>
        {options.map((opt) => (
          <Button
            key={opt.name}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              borderBottom: opt?.name === currentTab ? "4px solid orange" : "",
            }}
            onClick={() => handleTabChange(opt)}
          >
            {opt.name}
          </Button>
        ))}
      </Box>
    </Box>
  );
};

export default AppointmentStatus;
