import React from "react";
import Container from "@mui/material/Container";
import HeroSection from "../../components/HeroSection";
import Categories from "../../components/Category/Categories";
import { Typography } from "@mui/material";
const categories = [
  {
    name: "Police",
    image:
      "https://images.pexels.com/photos/532001/pexels-photo-532001.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Porter Services",
    image:
      "https://images.pexels.com/photos/6474529/pexels-photo-6474529.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Legal",
    image:
      "https://images.pexels.com/photos/48148/document-agreement-documents-sign-48148.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  },
  {
    name: "Domestic & Social Help",
    image:
      "https://images.pexels.com/photos/7176324/pexels-photo-7176324.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Health Emergency",
    image:
      "https://images.pexels.com/photos/3845129/pexels-photo-3845129.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Educational",
    image: "https://images.pexels.com/photos/4145153/pexels-photo-4145153.jpeg",
  },
];
const Home = () => {
  return (
    <div>
      <HeroSection />
      <Container maxWidth="lg">
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          my: 4,
          color: "#0C095D",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Service Categories
      </Typography>
        <Categories categories={categories} />
      </Container>
    </div>
  );
};

export default Home;
