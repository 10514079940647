import { Box, Button } from "@mui/material";
import React from "react";
import NO_RESULT_IMG from "../assets/images/noResult.jpg";
import { useNavigate } from "react-router-dom";

const EmptyList = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={NO_RESULT_IMG}
        style={{ height: 230,  objectFit: "contain",borderRadius:"20px" }}
      ></img>
      {/* <Button
        sx={{ textTransform: "none" }}
        onClick={() => navigate("/services/all")}
      >
        View All Services
      </Button> */}
    </Box>
  );
};

export default EmptyList;
